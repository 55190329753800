import { tokenAxios } from "../core/TokenAPIInterceptor";
import { masterAxios } from "../core/MasterAPIInterceptor";
import { otpAxios } from "../core/OtpAPIInterceptor";
import { logisticAxios } from "../core/LogisticApiInterceptor";

// it fetches label
export async function getLanguageLabels(languageCD, userInterfaceCD) {
    const response = await masterAxios.get(`langLabel/${userInterfaceCD}/${languageCD}`);
    return response?.data;
}

// Now it is in master api
export async function getlanguageDDO() {
    return await masterAxios.get(`config/language`)
        .then((response) => {
            response?.data.map((item) => {
                item.value = item.code;
                item.label = item.name
                return null;
            })
            return response?.data
        })
}

// it fetches active countries from api by code
export async function getActiveCountry(countryCode) {
    return await logisticAxios.get(`Country/get?countryCode=${countryCode}`)
        .then((response) => {
            if (response) {
                let country = [];
                let activeCountry = {
                    value: response?.data.uId,
                    label: response?.data.code
                }
                country.push(activeCountry);
                return country
            }
        })
}

// it fetches all active countries from api
export async function getAllActiveCountry() {
    return await logisticAxios.get(`Country/getDDO`)
        .then((response) => {
            if (response && response.data) {
                let country = response.data.map((c) => {
                    return {
                        //name: c.name,
                        value: c.name,
                        label: c.code
                    }
                }
                );
                return country
            }
        })
}

// validates mobile number
export async function validateLogin(mobileNo, role, languageCode) {
    return await tokenAxios.get(`user/validate-login/${mobileNo}/${role}/${languageCode}`)
        .then(response => {
            if (response)
                return response?.data;
        })
}

// it fetches various config data according to their group id or enum string
export async function getConfigByGroupId(groupId, languageCode) {
    return await logisticAxios.get(`ConfigCD/` + groupId + `?languageCode=${languageCode}`)
        .then((response) => {
            if (response?.data) {
                response?.data.forEach(item => {
                    item.label = item.cdValue;
                    item.value = item.id;
                });
            }
            return response?.data;
        })
}

// sends otp and returns otpUId
export async function sendOTP(otpDetails) {
    return await otpAxios.post('secureotp', otpDetails)
        .then(response => {
            if (response)
                return response?.data
        })
}

// validates mobile number
export async function validatePartner(formData) {
    return await tokenAxios.post('user/validate-web-signup', formData)
        .then(response => {
            if (response)
                return response.data;
        })
}


export async function setUserHistory() {
    return await logisticAxios.put('UserOnlineHistory/checkOut')
        .then(response => {
            if (response)
                return response?.data
        })
}

export async function setUserHistoryWithLat(latLong) {
    return await logisticAxios.put(`UserOnlineHistory/checkOut?checkOutLatLong=${latLong}`)
        .then(response => {
            if (response)
                return response?.data
        })
}

export async function validateUser() {
    const response = await logisticAxios.get(`User/driver/validate`);
    return response?.data;
}

export async function getUserProfile() {
    const response = await logisticAxios.get(`User/profile`);
    return response?.data;
}

export async function getUserVehicleList(userUid) {
    const response = await logisticAxios.get(`UserVehicle/list/` + userUid);
    return response?.data;
}

// api for checking active orders
export async function checkForActiveOrders() {
    const response = await logisticAxios.get(`Order/has-active-order`);
    return response?.data;
}

export async function getTaxInfoDetails() {
    return await logisticAxios.get(`TaxIdentity`)
        .then((response) => {
            return response?.data
        })
}

// validates plate number, series number and engine number
export async function validateVehicleData(vehicleData) {
    const response = await logisticAxios.post(`UserVehicle/check-validation?numberPlate=${vehicleData.numberPlate}&userVehicleUId=${vehicleData.userVehicleUId}`);
    return response?.data;
}