import React from 'react';
import Vooy from "../../../assets/logos/Vooy.png";
import Button from '../../../shared-component/Button';
import { getPaymentType } from '../../../common/CommonMethods';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PaymentListing = ({ cardListing, state, handleCardSelection, selectedCard, setSelectedCard, handleDeleteCard, subscription }) => {
    const navigate = useNavigate()

    const { labelList } = state
    return (
        <>
            <div className='subscription-plan container card-listing'>
                <div className='vooy-image'>
                    <img src={Vooy} alt="Vooy Logo" />
                </div>
                <div>
                    <h1>  {cardListing ?
                        labelList.filter(i => i.key === 'subscription.selectYourCard')[0]?.value
                        : labelList.filter(i => i.key === 'subscription.addCard')[0]?.value}
                    </h1>
                    <div className='cards'>
                        <div className={`card-box  ${cardListing?.length >= 5 ? 'height-300' : 'height-min'} ${cardListing?.length > 0 ? "box-shadow" : null} `} >
                            {
                                cardListing ? cardListing?.map((card, index) => (
                                    <div className='card-inner-box' key={index}>
                                        <Table >
                                            <tbody>
                                                <tr>
                                                    <td>{card.cardHolderName}</td>
                                                    <td>{card?.cardNumber}</td>
                                                    <td>
                                                        <img
                                                            src={getPaymentType(card?.cardType)}
                                                            alt=""
                                                            className={card?.cardType === "visa" ? "height-15" : "height-20"}
                                                        />
                                                    </td>
                                                    <td>{card?.expiryMonth}/{card?.expiryYear}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <div className='d-flex gap-3'>
                                            <input
                                                type="radio"
                                                name="cardDefault"
                                                value="true"
                                                checked={selectedCard === card || (card?.isDefault && selectedCard === null)}
                                                onChange={() => handleCardSelection(card)}
                                            />
                                            <i className="fa fa-solid fa-trash" onClick={() => handleDeleteCard(card)}></i>
                                        </div>
                                    </div>
                                )) :
                                    <label className='no-cards'>{labelList.filter(i => i.key === 'subscription.noCardsAvailable')[0]?.value}</label>
                            }

                        </div>
                    </div>

                    {
                        cardListing && !subscription?.isDriverSubscriptionActive && subscription?.isSubscriptionActive && !subscription?.isTrialPeriod &&
                        <>
                            <div className='subscription-btn'>
                                <Button btnType="primary" label={labelList.filter(i => i.key === 'subscription.subscribeBtn')[0]?.value} onClick={() => {
                                    navigate("/active-driver/subscription/card-details", {
                                        state: { values: selectedCard }
                                    })
                                }} />
                            </div>
                        </>
                    }
                </div>
            </div>

        </>
    );
};

export default PaymentListing;
