import React from 'react'
import Vooy from "../../../assets/logos/Vooy.png"
import Button from '../../../shared-component/Button'
import { useNavigate } from 'react-router-dom'

const SubscriptionPlan = ({ subscriptionPlan, state }) => {
    const { labelList } = state
    const navigate = useNavigate()
    return (
        <>
            <div className='subscription-container'>
                <div className='subscription-plan container'>
                    <div className='vooy-image' >
                        <img src={Vooy} />
                    </div>
                    <div>
                        <h1 className='heading1'> {labelList.filter(i => i.key === 'subscription.subscriptionPlanHeading1')[0]?.value}</h1>
                        <h3 className='heading2'>$  {subscriptionPlan?.price ? subscriptionPlan?.price : null} {subscriptionPlan?.currency}</h3>

                        <h5 className='text-center mt-3 color-white'> {labelList.filter(i => i.key === 'subscription.subscriptionPlanDescription')[0]?.value}</h5>

                        <div >
                            <div className='points'>
                                <i className="fa fa-solid fa-check"></i>
                                <p className='color-white'>{labelList.filter(i => i.key === 'subscription.subscriptionPlanList1')[0]?.value}</p>
                            </div>
                            <div className='points'>
                                <i className="fa fa-solid fa-check"></i>
                                <p className='color-white'>{labelList.filter(i => i.key === 'subscription.subscriptionPlanList2')[0]?.value}</p>
                            </div>
                        </div>
                        <div className='subscription-btn'>
                            <Button btnType="primary" type="submit" label={labelList.filter(i => i.key === 'subscription.proceedBtn')[0]?.value} onClick={() => navigate("/active-driver/subscription/cards")} />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriptionPlan