import React from 'react'
import Modal from 'react-bootstrap/Modal';
import AlertICon from "../../assets/icons/alertIcon.png"
import "../global.css"
import { useNavigate } from 'react-router-dom';
import Button from '../../shared-component/Button';

const SubscriptionRequired = ({ data, show, hide, labelList, handleConfirmationModalShow }) => {
    const navigate = useNavigate()

    const handleLogout = () => {
        hide()
        setTimeout(() => {
            handleConfirmationModalShow()
        }, 400)
    }
    return (
        <>
            <Modal className="modals" show={show} >
                <Modal.Body className='edit-modal-body'>
                    <div className='alert-icon'>
                        <img src={AlertICon} alt="Excellent Password" />
                    </div>
                    <div className='alert-content'>
                        <p>
                            {labelList.filter(i => i.key === 'subscription.subscriptionPlanRequiredPoint')[0]?.value}
                        </p>
                        <div className='buttons'>
                            <Button btnType="primary" label={labelList.filter(i => i.key === 'subscription.subscriptionPlanRequiredBtn')[0]?.value} onClick={() => navigate("/active-driver/subscription/subscription-plan")} />
                            <Button btnType="danger" label={labelList.filter(i => i.key === 'subscription.subscriptionPlanLogoutBtn')[0]?.value} onClick={() => handleLogout()} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SubscriptionRequired