import React from 'react'
import Modal from 'react-bootstrap/Modal';
import "../global.css"
import Button from '../../shared-component/Button';

const PayConfirmation = ({ show, hide, addSubscriptionPlan, state, loading }) => {

    const { labelList } = state

    return (
        <>
            <Modal className="modals" show={show} >
                <Modal.Body className='edit-modal-body mb-4'>
                    <div className='alert-content'>
                        <p>
                            {labelList.filter(i => i.key === 'subscription.confirmPay')[0]?.value}
                        </p>
                        <div className='buttons'>
                            <Button btnType="gray" label={labelList.filter(i => i.key === 'subscription.noBtn')[0]?.value} onClick={() => hide()} />
                            <Button btnType="primary" btnLoader={loading} label={labelList.filter(i => i.key === 'subscription.payBtn')[0]?.value} onClick={() => addSubscriptionPlan()} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PayConfirmation