import * as Yup from "yup";

export const initialPersonalInfo = {
  mobileNo: "",
  firstName: "",
  fatherLastName: "",
  motherLastName: "",
  genderCD: "",
  email: "",
  password: "",
  confirmPassword: "",
  referredBy: "",
  cityUId: "",
  stateUId: "",
};

const personalInfo = () => {
  return initialPersonalInfo;
};

const alpha = /^[a-zA-Zñáéíóúü][a-zA-Zñáéíóúü\s]*$/;
const regexEmail =
  /^(?!\.)(?!.*\.$)(?!.*?\.\.)(?!.*?\.@)([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
const regexMobileNumber =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const regexForAcceptAllExceptWhitespace = /^[^\s].*/;

// User detail form validation schema
export const formValidationSchema = (res, minMobileLength, maxMobileLength) => {
  return Yup.object().shape({
    mobileNo: Yup.string()
      .matches(regexMobileNumber, {
        message: res?.filter(
          (i) => i.key === "Validation.MobileNumberInvalid"
        )[0]?.value,
        excludeEmptyString: false,
      })
      .required(
        res?.filter((i) => i.key === "Validation.MobileNumberIsRequired")[0]
          ?.value
      )
      .min(
        minMobileLength,
        res?.filter((i) => i.key === "Validation.MobileNumberInvalid")[0]?.value
      )
      .max(
        maxMobileLength,
        res?.filter((i) => i.key === "Validation.MobileNumberInvalid")[0]?.value
      ),
    firstName: Yup.string()
      .matches(alpha, {
        message: res?.filter((i) => i.key === "Validation.NameIsInvalid")[0]
          ?.value,
        excludeEmptyString: true,
      })
      .required(
        res?.filter((i) => i.key === "Validation.NameIsRequired")[0]?.value
      )
      .max(50),
    motherLastName: Yup.string()
      .matches(regexForAcceptAllExceptWhitespace, {
        message: res?.filter(
          (i) => i.key === "Validation.MotherLastNameIsInvalid"
        )[0]?.value,
        excludeEmptyString: true,
      })
      .required(
        res?.filter((i) => i.key === "Validation.MotherLastNameIsRequired")[0]
          ?.value
      )
      .max(50),
    fatherLastName: Yup.string()
      .matches(regexForAcceptAllExceptWhitespace, {
        message: res?.filter(
          (i) => i.key === "Validation.FatherLastNameIsInvalid"
        )[0]?.value,
        excludeEmptyString: true,
      })
      .required(
        res?.filter((i) => i.key === "Validation.FatherLastNameIsRequired")[0]
          ?.value
      )
      .max(50),
    email: Yup.string()
      .matches(regexEmail, {
        message: res?.filter((i) => i.key === "Validation.EmailIsInvalid")[0]
          ?.value,
        excludeEmptyString: false,
      })
      .required(
        res?.filter((i) => i.key === "Validation.EmailIsRequired")[0]?.value
      ),
    genderCD: Yup.string().required(
      res?.filter((i) => i.key === "Validation.GenderIsRequired")[0]?.value
    ),
    password: Yup.string()
      .min(
        6,
        res?.filter((i) => i.key === "Validation.PasswordMinimumLength")[0]
          ?.value
      )
      .required(
        res?.filter((i) => i.key === "Validation.PasswordIsRequired")[0]?.value
      )
      .matches("^[^\\s]+$", {
        message: res?.filter(
          (i) => i.key === "Validation.PasswordInvalidError"
        )[0]?.value,
        excludeEmptyString: false,
      })
      .max(15),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        res?.filter((i) => i.key === "Validation.PasswordMismatchError")[0]
          ?.value
      )
      .required(
        res?.filter((i) => i.key === "Validation.ConfirmPasswordIsRequired")[0]
          ?.value
      )
      .max(15)
      .min(
        6,
        res?.filter((i) => i.key === "Validation.PasswordMinimumLength")[0]
          ?.value
      ),
    referredBy: Yup.string()
      .matches("^[^\\s]+$", {
        message: res?.filter((i) => i.key === "Validation.ReferralCodeError")[0]
          ?.value,
        excludeEmptyString: false,
      })
      .max(
        10,
        res?.filter((i) => i.key === "Validation.ReferralCodeLength")[0]?.value
      )
      .min(
        10,
        res?.filter((i) => i.key === "Validation.ReferralCodeLength")[0]?.value
      ),
    stateUId: Yup.string().required(
      res?.filter((i) => i.key === "Validation.StateIsRequired")[0]?.value
    ),
    cityUId: Yup.string().required(
      res?.filter((i) => i.key === "Validation.CityIsRequired")[0]?.value
    ),
  });
};

export const initialPersonalState = {
  labelList: [],
  genderList: [],
  personalInfo: personalInfo(),
  filledPersonalInfo: {},
  countryCode: "",
  countryUId: "",
  countryList: [],
  isAgreementAccepted: false,
  formValidationSchema: formValidationSchema(),
  isPasswordStrength: false,
  passwordStrength: {},
  remark: "",
  isNewRegister: false,
  mobileNoMaxLength: null,
};

// States
const personalState = (function states() {
  return initialPersonalState;
})();


export default personalState;
