import React from 'react';
import { Formik, Field, Form } from 'formik';
import './LoginModule.css';
import * as commonMethods from '../../common/CommonMethods';
import { Link } from "react-router-dom";
import FormikInput from '../../shared-component/formik/FormikInput';
import FormikSelect from '../../shared-component/formik/FormikSelect';

const LoginWithMobile = (props) => {

    const { labelList, loginDetails, formValidationSchemaMobile, countryList, mobileNoMaxLength } = props.state;
    const initialValues = { ...loginDetails, countryUI: props.state.countryUI };
    return (
        <div className='login-main-div'>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={formValidationSchemaMobile}
                innerRef={props.formikRef}
                onSubmit={fields => {
                    props.onLogin(fields)
                }}
            >
                {({ values, isValid, dirty }) => (
                    <React.Fragment >
                        <div className='login-container'>
                            <h2 className='login-title'>{labelList.filter(i => i.key === 'Login.SignIn')[0]?.value}</h2>
                            <Form noValidate>
                                <div className='form-group'>
                                    <div className='mobileNo-div'>
                                        <div className={`${'input-div'} ${values.countryUI && 'value-available'}`}>
                                            <Field value={values.countryUI} onChange={props.onChangeCountryListHandler} option={countryList}
                                                name='countryUI' id='countryUI' type='select' component={FormikSelect} />
                                            <span className='mobile-no-span'>
                                                <Field maxLength={mobileNoMaxLength} name='userName' id='userName' type='tel' onKeyDown={commonMethods.updateNumber} component={FormikInput}
                                                    placeholder={labelList.filter(i => i.key === 'Login.MobileNumberLabel')[0]?.value} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='change-login-option'>
                                    <label onClick={props.onChangeLoginOptionHandler}><span >{labelList.filter(i => i.key === 'Login.LoginWithEmail')[0]?.value}</span></label>
                                </div>
                                <div className={(isValid && dirty && values.countryUI) ? 'login-button-div' : 'login-button-div disable-btn'} >
                                    <button type='submit' >{labelList.filter(i => i.key === 'Login.LoginButton')[0]?.value}</button>
                                </div>
                                <div className='signup-div'>
                                    <label>{labelList.filter(i => i.key === 'Login.DoNotHaveAccountLabel')[0]?.value}</label>&nbsp;
                                    <label onClick={props.signUp} className='signup-label'> {labelList.filter(i => i.key === 'Login.SignUpButton')[0]?.value}</label>
                                </div>
                                <div className="forgot-pass-div">
                                    <Link to="/forgot-password">{labelList.filter(i => i.key === 'Login.ForgotPasswordLink')[0]?.value}</Link>
                                </div>
                                <div className='language-div value-available'>
                                    <i className='fas fa-globe-americas'></i>
                                    <Field name='language' id='language' className='decorated' type='select' onChange={props.onChangeLanguageListHandler} option={props.state.languageList} component={FormikSelect} />
                                </div>
                            </Form>
                        </div>
                    </React.Fragment >
                )}
            </Formik >
        </div >
    )
}

export default LoginWithMobile;