import React, { Fragment, useEffect, useState } from 'react';
import './Profile.css';
import ProfileImage from '../../../assets/banners/ProfileImage.png';
import * as commonMethods from "../../../common/CommonMethods";
import { Formik, Field } from 'formik';
import FormikInput from '../../../shared-component/formik/FormikInput';
import FormikSelect from '../../../shared-component/formik/FormikSelect';
import Slide from "@material-ui/core/Slide";
import Card from '@material-ui/core/Card';
import Modal from 'react-bootstrap/Modal';
import OtpField from 'react-otp-field';
import SubscriptionRequired from '../../../modals/subscription-required/SubscriptionRequired';
import LogoutConfirmation from '../../../modals/logout-confirmation/LogoutConfirmation';
// import TrialSubscription from '../../../modals/subscription-trial/TrialSubscription';
// import { getLocalStorage, setLocalStorage } from '../../../common/CommonMethods';
import useModal from './useModalHook';

const Profile = (props) => {

    const { labelList, profileInfo, genderList, currentProfileImageURL, mobileNoMaxLength,
        formValidationSchema, partnerProfileResponse, partnerUId, subscription } = props.state;
    const [show, handleShow, handleClose] = useModal(false);
    const [enableConfirmationModal, handleConfirmationModalShow, handleConfirmationModalClose] = useModal(false);
    // const [enableTrialModal, handleTrialModalShow, handleTrialModalClose] = useModal(false);

    useEffect(() => {
        subscription?.isSubscriptionActive && !subscription?.isDriverSubscriptionActive  && !subscription?.isTrialPeriod ? handleShow() : handleClose();
    }, [subscription]);


    // useEffect(() => {
    //     const hasModalBeenShown = getLocalStorage("modalShown")
    //     if (!hasModalBeenShown) {
    //         if (subscription?.isTrialPeriod && !subscription?.isDriverSubscriptionActive && subscription?.isSubscriptionActive) {
    //             handleTrialModalShow()
    //             setLocalStorage('modalShown', 'true')
    //         } else {
    //             handleTrialModalClose();
    //         }
    //     }
    // }, [subscription]);


    // common email field jsx element 
    const EmailField = <div className="profile-input-div">
        <label>{labelList.filter(i => i.key === 'Profile.EmailLabel')[0]?.value}</label>
        <div className='email-mobile-div'>
            <Field name="email" id="email" type="text" component={FormikInput} disabled={!props.state.isEditEmail} />
            {
                !props.state.isEditEmail &&
                <button className='profile-edit-btn' type='button' onClick={() => props.openChoosenCredentialModal('email')}>
                    {labelList.filter(i => i.key === 'Profile.EditButton')[0]?.value}
                </button>
            }
        </div>
    </div>

    // common mobile field jsx element 
    const MobileField = <div className="profile-input-div">
        <label>{labelList.filter(i => i.key === 'Profile.MobileNumberLabel')[0]?.value}</label>
        <div className='email-mobile-div'>
            <div className='profile-mobile-div'>
                {!props.state.isEditMobile &&
                    <div className='fixed-field-div'>
                        <Field name="dc" id="dc" type="text" component={FormikInput} disabled={true} />
                    </div>
                }
                <Field maxLength={mobileNoMaxLength} name='mobileNo' value={props.state.isEditMobile && ''} disabled={!props.state.isEditMobile}
                    id='mobileNo' type='tel' onKeyDown={commonMethods.updateNumber} component={FormikInput} />
            </div>
            {
                !props.state.isEditMobile &&
                <button className='profile-edit-btn' type='button' onClick={() => props.openChoosenCredentialModal('mobile')}>
                    {labelList.filter(i => i.key === 'Profile.EditButton')[0]?.value}
                </button>
            }
        </div>
    </div>
    return (
        <Formik
            initialValues={profileInfo}
            validationSchema={formValidationSchema}
            validateOnChange={true}
            validateOnBlur={false}
        >
            {(formik, form) => (
                <Fragment>
                    <div className='profile-container'>
                        <Card className='M-ui-card'>
                            <Slide direction="right" timeout={500} in={true} mountOnEnter unmountOnExit>
                                <div className='profile-div'>
                                    <label className='profile-label'>{labelList.filter(i => i.key === 'Profile.ProfileLabel')[0]?.value}</label>
                                    <hr />
                                    <div className={!!partnerUId ? `profile-image-div div-disabled` : `profile-image-div`}>
                                        <label>{labelList.filter(i => i.key === 'Profile.ProfilePicture')[0]?.value}</label>
                                        <label htmlFor='profileImage' className='profile-image-label'>
                                            <img src={partnerProfileResponse?.isDefaultPic ? ProfileImage : currentProfileImageURL} alt='ProfileImage' />
                                        </label>
                                        {
                                            partnerProfileResponse?.isDefaultPic ?
                                                <input type="file" id='profileImage' accept="image/png, image/jpeg, image/jpg, capture=camera"
                                                    onChange={(event) => props.uploadProfilePicture(event)}
                                                    onClick={(event) => commonMethods.SetInputTypeFileFieldNull(event)}
                                                /> : null
                                        }
                                    </div>
                                    <div className="profile-input-div">
                                        <label>{labelList.filter(i => i.key === 'Profile.Name')[0]?.value}</label>
                                        <Field name="firstName" disabled={!!partnerUId} maxLength='50' id="name" type="text" component={FormikInput} />
                                    </div>
                                    <div className="profile-input-div">
                                        <label>{labelList.filter(i => i.key === 'Profile.PaternalSurname')[0]?.value}</label>
                                        <Field name="fatherLastName" disabled={!!partnerUId} maxLength='50' id="name" type="text" component={FormikInput} />
                                    </div>
                                    <div className="profile-input-div">
                                        <label>{labelList.filter(i => i.key === 'Profile.MaternalSurname')[0]?.value}</label>
                                        <Field name="motherLastName" disabled={!!partnerUId} maxLength='50' id="name" type="text" component={FormikInput} />
                                    </div>

                                    {EmailField}
                                    {MobileField}

                                    <div className="profile-input-div">
                                        <label>{labelList.filter(i => i.key === 'Profile.Gender')[0]?.value}</label>
                                        <Field name="genderCD" id="genderCD" type="select" disabled={true}
                                            option={genderList} component={FormikSelect} />
                                    </div>

                                    <div className='profile-btn-div'>
                                        <button className={(formik.isValid && formik.dirty) ? 'profile-cancel-btn' : 'profile-cancel-btn disable-cancel-btn'} type='button' onClick={() => props.cancelUpdatingProfileInfo(formik.resetForm)}>
                                            {labelList.filter(i => i.key === 'Profile.CancelBtn')[0]?.value}
                                        </button>
                                        <button className={(formik.isValid && formik.dirty) ? 'profile-save-btn' : 'profile-save-btn disable-btn-profile'} type='submit' onClick={() => {
                                            props.updateProfileInfo(formik.values);
                                            formik.resetForm();
                                        }}>
                                            {labelList.filter(i => i.key === 'Profile.SaveBtn')[0]?.value}</button>

                                    </div>

                                    <Modal className="modal-info" show={props.state.isEditEmail || props.state.isEditMobile} onHide={() => props.closeEditModal(formik.resetForm)}>
                                        <Modal.Body className='edit-modal-body'>
                                            {props.state.isEditMobile && <div> {MobileField} </div>}
                                            {props.state.isEditEmail && <div> {EmailField} </div>}

                                            <div className='modal-btn-div'>
                                                <button className='profile-cancel-btn' type='button' onClick={() => { props.closeEditModal(formik.resetForm); }}>
                                                    {labelList.filter(i => i.key === 'Profile.CancelBtn')[0]?.value}
                                                </button>
                                                <button className={(formik.isValid && formik.dirty) ? 'profile-save-btn' : 'profile-save-btn disable-btn-profile'} type='submit'
                                                    onClick={() => props.sendOtp(props.state.isEditEmail ? 'email' : 'mobile', formik.values, false)}>
                                                    {labelList.filter(i => i.key === 'PersonalDocument.SubmitButton')[0]?.value}</button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                    <Modal className="modal-info" show={props.state.otpUId ? true : false} onHide={() => props.closeEditModal(formik.resetForm)}>
                                        <Modal.Body className='edit-modal-body'>
                                            <div className="otp-label-div">
                                                <label>{labelList.filter(i => i.key === 'VerifyOTP.VerifyOTP')[0]?.value}</label>
                                            </div>
                                            <div className="otp-modal-div">
                                                <OtpField
                                                    value={props.state.otp}
                                                    onChange={props.onChangeOtpHandler}
                                                    numInputs={6}
                                                    onChangeRegex={/^([0-9]{0,})$/}
                                                    autoFocus
                                                    type='tel'
                                                    isTypeNumber={true}
                                                    separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                                />
                                                <div className="resend-otp-modal">
                                                    <label className="resend-first-label"> {labelList.filter(i => i.key === 'VerifyOTP.DidNotReceiveOtp')[0]?.value}&nbsp;&nbsp;</label>
                                                    <label className="resend-label" onClick={() => props.sendOtp('email', formik.values, true)}>
                                                        {labelList.filter(i => i.key === 'VerifyOTP.Resend')[0]?.value}</label>
                                                </div>
                                            </div>

                                            <div className='modal-btn-div'>
                                                <button className='profile-cancel-btn' type='button' onClick={() => { props.closeEditModal(formik.resetForm); }}>
                                                    {labelList.filter(i => i.key === 'Profile.CancelBtn')[0]?.value}
                                                </button>
                                                <button className={props.state.otp.length === 6 ? 'profile-save-btn' : 'profile-save-btn disable-btn-profile'} type='submit' onClick={() => props.submitOtp()}>
                                                    {labelList.filter(i => i.key === 'PersonalDocument.SubmitButton')[0]?.value}</button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                    {/* {
                                        enableTrialModal && <TrialSubscription data={subscription} show={handleTrialModalShow} hide={handleTrialModalClose} labelList={labelList} />
                                    } */}
                                    {
                                        show && <SubscriptionRequired data={subscription} show={handleShow} hide={handleClose} handleConfirmationModalShow={handleConfirmationModalShow} labelList={labelList} />
                                    }

                                    {
                                        enableConfirmationModal && <LogoutConfirmation showSubscriptionModal={handleShow} show={handleConfirmationModalShow} hide={handleConfirmationModalClose} labelList={labelList} />
                                    }
                                </div>
                            </Slide >
                        </Card >
                    </div>
                </Fragment>
            )}
        </Formik >
    )
}

export default Profile;