import React, { useState, useEffect, useRef } from 'react'
import * as subscriptionService from '../../../service/subscriptionService';
import { getLocalStorage, removeAsyncStorage } from '../../../common/CommonMethods';
import SubscriptionPlan from './SubscriptionPlan';
import subscriptionState from "./SubscriptionPlanState"
import "../subscriptionModule.css"

const SubscriptionPlanContainer = () => {
    const [state, setState] = useState(subscriptionState);
    const [subscriptionPlan, setSubscriptionPlan] = useState([])
    const isComponentMounted = useRef(true);

    useEffect(() => {
        getSubscriptionPlans()
    }, [])

    const getSubscriptionPlans = async () => {
        const response = await subscriptionService.getSubscriptionPlan()
        if (response) {
            setSubscriptionPlan(response)
        }
    }

    useEffect(() => {
        let masterApiData = JSON.parse(getLocalStorage('languageApiLabels'));
        removeAsyncStorage();
        if (isComponentMounted.current) {
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });
        }
        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [state.labelList])



    return (
        <>
            <SubscriptionPlan subscriptionPlan={subscriptionPlan} state={state} />
        </>
    )
}

export default SubscriptionPlanContainer