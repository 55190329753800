import React from 'react';
import moment from 'moment';
import Button from '../../../shared-component/Button';
import { useNavigate } from 'react-router-dom';
import { getSubscriptionStatus } from '../../../common/CommonMethods';
import { isEmpty } from 'lodash';

const SubscriptionDetail = ({ data, state, cancelSubscriptionPlan, loading }) => {
    const navigate = useNavigate();

    const { labelList } = state
    const subscriptionEndDate = data && moment(data?.subscriptionEndDate, 'DD-MM-YYYY').format('Do MMMM YYYY');
    const trialEndDate = data?.trialEndDate && moment(data?.trialEndDate, 'DD-MM-YY').format('Do MMMM YYYY');
    const isDriverSubscriptionActive = data?.isDriverSubscriptionActive && data?.reason === "Active";
    const isTrialActive = !data?.isDriverSubscriptionActive && data?.isTrialPeriod;


    return (
        <div className='subscription-plan container validate-details'>
            <div className='heading'>
                <h1>{labelList.filter(i => i.key === 'subscription.subscriptionDetails')[0]?.value}</h1>
            </div>
            <div className='validate-detail-container mt-4'>
                <div className='validate-detail-inner-box'>
                    <div>
                        <label>{labelList.filter(i => i.key === 'subscription.subscription')[0]?.value}</label>
                        <span className={isDriverSubscriptionActive ? "activeSubscription" : "inActiveSubscription"}>
                            {isDriverSubscriptionActive
                                ? labelList.filter(i => i.key === 'subscription.active')[0]?.value
                                : labelList.filter(i => i.key === 'subscription.notActive')[0]?.value}
                        </span>
                    </div>

                    <div className='mt-3'>
                        <label>{labelList.filter(i => i.key === 'subscription.subscriptionStatus1')[0]?.value}</label>
                        <span className={isDriverSubscriptionActive ? "activeSubscription" : "inActiveSubscription"}>
                            {
                                labelList.filter((i) => i.key === `subscription.${getSubscriptionStatus(data)}`)[0]?.value
                            }
                        </span>
                    </div>
                    {isTrialActive && !isEmpty(data?.trialEndDate) && (
                        <div className='mt-3'>
                            <label>{labelList.filter(i => i.key === 'subscription.trialPeriod')[0]?.value}</label>
                            <span className={isTrialActive ? "activeSubscription" : "inActiveSubscription"}>
                                {isTrialActive
                                    ? labelList.filter(i => i.key === 'subscription.active')[0]?.value
                                    : labelList.filter(i => i.key === 'subscription.notActive')[0]?.value}
                            </span>
                        </div>
                    )}
                    {isTrialActive && !isEmpty(data?.trialEndDate) && (
                        <div className='mt-3'>
                            <label>{labelList.filter(i => i.key === 'subscription.trialEndDate')[0]?.value}</label>
                            <span>{trialEndDate ? trialEndDate : null}</span>
                        </div>
                    )}
                    {data?.subscriptionEndDate && (

                        <div className='mt-3'>
                            <label>{labelList.filter(i => i.key === 'subscription.nextPaymentDate')[0]?.value}</label>
                            <span>{subscriptionEndDate ? subscriptionEndDate : null}</span>
                        </div>
                    )}
                </div>
            </div>
            <div className='subscription-btn'>
                {
                    data?.isDriverSubscriptionActive ?
                        <Button btnType="danger" btnLoader={loading} label={labelList.filter(i => i.key === 'subscription.cancelSubscription')[0]?.value} onClick={() => cancelSubscriptionPlan()} />
                        : !data?.isDriverSubscriptionActive && data?.isSubscriptionActive && !data?.isTrialPeriod ? <Button btnType="primary" label={labelList.filter(i => i.key === 'subscription.subscribe')[0]?.value} onClick={() => navigate("/active-driver/subscription/subscription-plan")} />
                            : null}
            </div>
        </div>
    );
};

export default SubscriptionDetail;
