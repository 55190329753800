import React, { useEffect, useState } from 'react';
import profileState, { formValidationSchema } from './ProfileState';
import * as commonService from '../../../service/CommonService';
import * as activeAccountService from '../../../service/ActiveAccountService';
import { toast } from 'react-toastify';
import * as commonMethods from "../../../common/CommonMethods";
import * as loginService from '../../../service/Login.Service';
import * as subscriptionService from '../../../service/subscriptionService';
import Profile from './Profile';
import { useNavigate } from 'react-router-dom';
import { AppEnum } from '../../../common/AppEnum';
import isEmpty from "lodash"

const ProfileContainer = () => {

    const navigate = useNavigate()
    const [state, setState] = useState(profileState);
    const [coordinates, setCordinates] = useState('');

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        setState((prevState) => { return { ...prevState, labelList: masterApiData } })
        getPartnerProfile(masterApiData);
        getGenderList();
        commonMethods.getCurrentLocation()
            .then((location) => {
                const concatenatedCoordinates = location.latitude.toString() + location.longitude.toString();
                setCordinates(concatenatedCoordinates)
                console.log("coordinates", coordinates)
            })
            .catch((error) => {
                console.warn("Error getting location:", error.message);
            });

    }, [])


    // it fetches partner's old profile details
    const getPartnerProfile = (masterApiData) => {
        activeAccountService.getUserProfile().then((response) => {
            if (response) {
                let profileInfo = state.profileInfo;
                profileInfo.firstName = response.firstName + (response.lastName ? response.lastName : '');
                profileInfo.fatherLastName = response.fatherLastName;
                profileInfo.motherLastName = response.motherLastName;
                profileInfo.email = response.email;
                profileInfo.mobileNo = response.mobileNo;
                profileInfo.dc = response.dialCode;
                profileInfo.genderCD = response.genderCD;
                profileInfo.uId = response.uId;
                commonMethods.setLocalStorage("profileInfo", JSON.stringify(profileInfo));

                if (masterApiData)
                    setFormValidations(masterApiData, response.dialCode);

                setState((prevState) => {
                    return {
                        ...prevState, profileInfo: profileInfo,
                        currentProfileInfo: profileInfo,
                        currentProfileImageURL: response.profilePicURL,
                        partnerProfileResponse: response, dialCode: response.dialCode,
                        partnerUId: response.uId
                    }
                })
                getSubscriptionPlanValidation(response.uId);
            }
        })
    }



    // checking user subscription plan validations
    const getSubscriptionPlanValidation = (userId) => {
        subscriptionService.getSubscriptionPlanValidation(userId).then(res => {
            if (res) {
                setState((prevState) => {
                    return {
                        ...prevState, subscription: res
                    }
                })
                commonMethods.setLocalStorage("subscriptionDetails", JSON.stringify(res));

                if (res?.isSubscriptionActive === false) {
                    commonService.setUserHistory()
                    const latLong = "19.438105-99.202766";
                    commonService.setUserHistoryWithLat(isEmpty(coordinates) ? latLong : coordinates);

                }
            }
        })

    }

    // it sets form validation schema 
    const setFormValidations = (masterApiData, dialCode) => {

        let mobileValidation = commonMethods.getMinMaxMobileLength(masterApiData, dialCode);
        let validationSchema = formValidationSchema(masterApiData, mobileValidation.minMobileLength,
            mobileValidation.maxMobileLength);

        setState((prevState) => {
            return {
                ...prevState, formValidationSchema: validationSchema, mobileNoMaxLength: mobileValidation.maxMobileLength
            }
        })
    }

    // retrieves the gender list from API
    const getGenderList = () => {
        let languageCode = commonMethods.getLocalStorage('languageCode');
        commonService.getConfigByGroupId(AppEnum.ConfigCDGroupId.GENDER, languageCode).then(res => {
            if (res) {
                setState((prevState) => { return { ...prevState, genderList: [...res] } })
            }
        })
    }

    // if partner has already uploaded the profile picture then it will update the previous 
    // profile pic, otherwise it will upload a new profile picture
    const uploadProfilePicture = (event) => {
        let formData = new FormData();
        const file = event.target.files[0];
        let allowedFileSize = Number(state.labelList.filter(i => i.key === 'Validation.AllowedFileSize')[0].value) * 1000000;
        if (file) {
            if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
                if (file.size <= allowedFileSize) {
                    formData.append(file.name, file);
                    uploadCompressedProfilePicture(formData);
                }
                else {
                    commonMethods.compressUploadedFile(file, Number(state.labelList.filter(i => i.key === 'Validation.CompressedFileSize')[0]?.value))
                        .then((blobFile) => {
                            if (blobFile) {
                                var newFile = new File([blobFile], blobFile.name, { type: blobFile.type });
                                formData.append(file.name, newFile);
                                uploadCompressedProfilePicture(formData);
                            }
                        })
                }
            }
            else
                toast.error(state.labelList.filter(i => i.key === 'VehicleInfo.SupportedFormatLabel')[0]?.value);
        }
    }

    // if size is large then the compressed file will be uploaded
    const uploadCompressedProfilePicture = (formData) => {
        activeAccountService.uploadProfileImage(formData)
            .then((response) => {
                if (response) {
                    let partnerProfileResponse = state.partnerProfileResponse;
                    partnerProfileResponse.isDefaultPic = false;
                    setState((prevState) => {
                        return {
                            ...prevState, currentProfileImageURL: response.profilePicURL,
                            partnerProfileResponse: partnerProfileResponse
                        }
                    })
                    toast.success(state.labelList.filter(i => i.key === 'Validation.ProfileUpdated')[0]?.value);
                }
            })
    }

    // it resets the state to the current info so that any changes done by the partner will not be saved
    const cancelUpdatingProfileInfo = (resetForm) => {
        resetForm();
    }

    // it updates the profile info
    const updateProfileInfo = (profileInfo) => {
        let previousPartnerProfileInfo = state.partnerProfileResponse;

        previousPartnerProfileInfo.timeZone = new Date().getTimezoneOffset();
        previousPartnerProfileInfo.firstName = profileInfo.firstName?.split(' ')[0];
        previousPartnerProfileInfo.lastName = profileInfo.firstName?.substring(previousPartnerProfileInfo?.firstName.length);
        previousPartnerProfileInfo.fatherLastName = profileInfo.fatherLastName;
        previousPartnerProfileInfo.motherLastName = profileInfo.motherLastName;

        let partnerConfig = {
            user: previousPartnerProfileInfo
        }

        activeAccountService.savePartnerProfileInfo(partnerConfig).then((response) => {
            if (response) {
                getPartnerProfile(false);
                toast.success(state.labelList.filter(i => i.key === 'Validation.ProfileInfoUpdated')[0]?.value)
            }
        })
    }

    // closes the mobile and email edit modal and resets the form values
    const closeEditModal = (resetForm) => {
        resetForm();
        let profileInfo = state.profileInfo;
        profileInfo.email = state.partnerProfileResponse?.email;
        profileInfo.mobileNo = state.partnerProfileResponse?.mobileNo;
        setState((prevState) => {
            return {
                ...prevState, isEditMobile: false, isEditEmail: false,
                profileInfo: profileInfo, currentProfileInfo: profileInfo, otpUId: ''
            }
        });
    }

    // closes the otp modal
    const closeOtpModal = () => {
        setState((prevState) => {
            return {
                ...prevState, otpUId: ''
            }
        });
    }

    // opens the choosen credential in modal to edit and sets the field values to empty
    const openChoosenCredentialModal = (credential) => {
        if (credential === 'email') {
            let profileInfo = state.profileInfo;
            profileInfo.email = '';
            setState((prevState) => {
                return { ...prevState, isEditEmail: true, profileInfo: profileInfo }
            });
        }
        if (credential === 'mobile') {
            let profileInfo = state.profileInfo;
            profileInfo.mobileNo = '';
            setState((prevState) => {
                return { ...prevState, isEditMobile: true, profileInfo: profileInfo }
            });
        }
    }

    // it sends the otp to the email or mobile number depending on the choosen edit option
    const sendOtp = (credential, credentialValue, isResendOtp) => {
        let otpDetail = {};

        otpDetail.linkTableCD = AppEnum.LinkTableCD.driver;
        // otpDetail.linkTableUId = '';
        otpDetail.secureActionTypeCD = AppEnum.LoginCD.EMAIL_MOBILE_CHANGE;
        otpDetail.languageCode = localStorage.getItem('languageCode');

        if (credential === 'email') {
            otpDetail.sendToMobile = false;
            otpDetail.sendToEmail = true;
            otpDetail.email = credentialValue.email;
            otpDetail.mobileNo = '';
            activeAccountService.validateMobileNoOrEmail(otpDetail)
                .then((response) => {
                    if (response) {
                        setOtpUId(response.uId, credentialValue);
                        if (isResendOtp)
                            toast.success(state.labelList.filter(i => i.key === 'Validation.OtpSentSuccessfully')[0]?.value);
                    }
                })
        }
        if (credential === 'mobile') {
            otpDetail.sendToMobile = true;
            otpDetail.sendToEmail = false;
            otpDetail.email = '';
            otpDetail.mobileNo =
                state.dialCode +
                credentialValue.mobileNo;
            activeAccountService.validateMobileNoOrEmail(otpDetail)
                .then((response) => {
                    if (response) {
                        if (response) {
                            setOtpUId(response.uId, credentialValue);
                            if (isResendOtp)
                                toast.success(state.labelList.filter(i => i.key === 'Validation.OtpSentSuccessfully')[0]?.value);
                        }
                    }
                })
        }
    }

    // sets the otpUid
    const setOtpUId = (uId, credentialValue) => {
        let profileInfo = state.profileInfo;
        profileInfo.email = credentialValue.email;
        profileInfo.mobileNo = credentialValue.mobileNo;
        setState((prevState) => {
            return {
                ...prevState, otpUId: uId, otp: '', profileInfo: profileInfo
            }
        });
    }

    // Sets the otp in state
    const onChangeOtpHandler = (otp) => {
        if (otp.length >= 0)
            setState((prevState) => { return { ...prevState, otp: otp } });
    }

    // submits the otp and changes the credential
    const submitOtp = () => {
        let otpConfig = {};
        otpConfig.uId = state.partnerProfileResponse.uId;
        otpConfig.email = state.isEditEmail ? state.profileInfo.email : '';
        otpConfig.dialCode = state.dialCode;
        otpConfig.mobileNo = state.isEditMobile ?
            (
                // state.dialCode +
                state.profileInfo.mobileNo) : '';
        otpConfig.otpUId = state.otpUId;
        otpConfig.emailOTP = state.isEditEmail ? state.otp : '';
        otpConfig.mobileOTP = state.isEditMobile ? state.otp : '';

        activeAccountService.submitEmailOrMobileOtp(otpConfig).then((response) => {
            if (response) {
                setState((prevState) => {
                    return { ...prevState, isEditMobile: false, isEditEmail: false, otpUId: '' }
                });
                let accessToken = localStorage.getItem('token');
                let logoutConfig = {
                    authToken: accessToken,
                    fcmToken: '',
                    userUId: state.partnerProfileResponse.uId
                }
                loginService.logout(logoutConfig).then((res) => {
                    if (res) {
                        commonMethods.removeToken();
                        commonMethods.removeAsyncStorage();
                        navigate('/login');
                        if (state.isEditEmail)
                            toast.success(state.labelList.filter(i => i.key === 'Validation.EmailUpdated')[0]?.value);
                        else
                            toast.success(state.labelList.filter(i => i.key === 'Validation.MobileNoUpdated')[0]?.value);
                    }
                })
            }
        })
    }

    return (
        <React.Fragment>
            <Profile
                state={state}
                uploadProfilePicture={uploadProfilePicture}
                cancelUpdatingProfileInfo={cancelUpdatingProfileInfo}
                updateProfileInfo={updateProfileInfo}
                closeEditModal={closeEditModal}
                openChoosenCredentialModal={openChoosenCredentialModal}
                sendOtp={sendOtp}
                onChangeOtpHandler={onChangeOtpHandler}
                submitOtp={submitOtp}
                closeOtpModal={closeOtpModal}
            />
        </React.Fragment>
    )
}

export default ProfileContainer;