import { localAxios } from "../core/LocalApiInterceptor";

export async function getSubscriptionPlanValidation(UserId) {
    const response = await localAxios.get(`Subscription/Validate-Plan-Subscription?UserId=${UserId}`);
    return response?.data;
}

export async function getSubscriptionPlan() {
    const response = await localAxios.get(`Subscription/Plan-Info`);
    return response?.data;
}

export async function addCardDetails(payload) {
    const response = await localAxios.post(`card/detail`, payload);
    return response?.data;
}

export async function getCardListing() {
    const response = await localAxios.get(`card/detail`);
    return response?.data;
}

export async function deleteCard(UserId) {
    const response = await localAxios.delete(`card/detail/${UserId}`);
    return response?.data;
}

export async function createSubscriptionPlan(payload) {
    const response = await localAxios.post(`Subscription/Subscribe `, payload);
    return response?.data;
}

export async function cancelSubscriptionPlan(UserId) {
    const response = await localAxios.post(`Subscription/Cancel-Driver-Subscription?UserUId=${UserId}`);
    return response?.data;
}