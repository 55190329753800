import React, { useState, useEffect, useRef } from 'react'
import * as subscriptionService from '../../../service/subscriptionService';
import { getLocalStorage, removeAsyncStorage } from '../../../common/CommonMethods';
import "../subscriptionModule.css"
import PaymentListingState from './PaymentListingState';
import PaymentListing from './PaymentListing';
import { toast } from 'react-toastify';
import { Tab, Tabs } from 'react-bootstrap';
import AddCardDetailContainer from '../AddCardDetails/AddCardDetailContainer';

const PaymentListingContainer = () => {

    const [state, setState] = useState(PaymentListingState);
    const [cardListing, setCardListing] = useState([])
    const isComponentMounted = useRef(true);
    const [selectedCard, setSelectedCard] = useState(null);
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);
    const userData = JSON.parse(getLocalStorage('profileInfo'));

    useEffect(() => {
        getCardListings()
        getSubscriptionPlans()
    }, [])

    const getCardListings = async () => {
        const response = await subscriptionService.getCardListing()
        if (response) {
            setCardListing(response)
        }
    }

    const getSubscriptionPlans = async () => {
        const response = await subscriptionService.getSubscriptionPlanValidation(userData?.uId)
        if (response) {
            setSubscriptionPlan(response)
        }
    }

    useEffect(() => {
        let masterApiData = JSON.parse(getLocalStorage('languageApiLabels'));
        removeAsyncStorage();
        if (isComponentMounted.current) {
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });
        }
        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [state.labelList])


    useEffect(() => {
        const defaultCard = cardListing && cardListing?.find((card) => card.isDefault);
        if (defaultCard) {
            setSelectedCard(defaultCard);
        }
    }, [cardListing]);
    const handleCardSelection = (card) => {
        setSelectedCard(card);
    };

    const handleDeleteCard = async (card) => {
        const res = await subscriptionService.deleteCard(card?.uId)
        if (res && res?.recordAffected === 1) {
            getCardListings()
            toast.success(state?.labelList.filter(i => i.key === 'subscription.cardDeletedSuccessfully')[0]?.value)
        }
    }
    return (
        <div className='container mt-5'>
            <Tabs
                defaultActiveKey="existingCards"
                id="fill-tab-example"
                className="mb-3"
                justify
            >
                <Tab eventKey="existingCards" title={state?.labelList.filter(i => i.key === 'subscription.existingCard')[0]?.value} id='existingCards'>
                    <PaymentListing handleDeleteCard={handleDeleteCard} handleCardSelection={handleCardSelection} cardListing={cardListing} state={state} selectedCard={selectedCard} setSelectedCard={setSelectedCard} subscription={subscriptionPlan} />
                </Tab>
                <Tab eventKey="addNewCard" title={state?.labelList.filter(i => i.key === 'subscription.addNewCardTabName')[0]?.value} id='addNewCard'>
                    <AddCardDetailContainer />
                </Tab>

            </Tabs>
        </div>
    )
}

export default PaymentListingContainer