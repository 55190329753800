import React, { useEffect, useRef, useState } from 'react'
import "../subscriptionModule.css"
import "react-datepicker/dist/react-datepicker.css";
import conektaHelper from '../conektaHelper'
import { getMonthYearInDigits, getLocalStorage } from '../../../common/CommonMethods'
import * as subscriptionService from '../../../service/subscriptionService';
import { toast } from 'react-toastify'
import AddCardDetail from './AddCardDetail'
import cardState, { cardDetailsSchema } from './AddCardDetailState';
import { useNavigate } from 'react-router-dom';

const AddCardDetailContainer = () => {
    const [conektaLoaded, setConektaLoaded] = useState(false);
    const [state, setState] = useState(cardState);
    const [loading, setLoading] = useState(false);
    const userData = JSON.parse(getLocalStorage('profileInfo'));
    const isComponentMounted = useRef(true);
    const navigate = useNavigate()
    useEffect(() => {
        const loadConekta = async () => {
            const script = document.createElement('script');
            script.src = 'https://cdn.conekta.io/js/latest/conekta.js';
            script.async = true;
            script.onload = () => {
                conektaHelper.initConekta();
                setConektaLoaded(true);
            };
            document.body.appendChild(script);
        };
        loadConekta();
    }, []);

    useEffect(() => {
        let masterApiData = JSON.parse(getLocalStorage('languageApiLabels'));
        let validationSchema = cardDetailsSchema(masterApiData);
        setState((prevState) => { return { ...prevState, cardDetailsSchema: validationSchema } });
        if (isComponentMounted.current) {
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });
        }
        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [state.labelList])


    function handleApiResponse(res, values) {
        if (res && res?.uId) {
            const updatedValues = { ...values, uId: res?.uId };
            toast.success(state?.labelList?.filter(i => i.key === 'subscription.CardAddedSuccess')[0]?.value)
            setLoading(false)
            navigate("/active-driver/subscription/card-details", {
                state: { values: updatedValues }
            });
        } else if (res?.hasError && res?.validationErrors && res?.validationErrors?.errorMessage) {
            toast.error(res?.validationErrors?.errorMessage);
            setLoading(false)
        } else if (res?.hasError && res?.message && res?.message === "string") {
            toast.error(state?.labelList?.filter(i => i.key === 'subscription.CardAddedError')[0]?.value)
            setLoading(false)
        }
    }

    const handleAddDetail = (values) => {
        if (conektaLoaded) {
            setLoading(true)
            console.log('Conekta is loaded, attempting tokenization');
            conektaHelper.tokenize(
                values?.cardNumber,
                values?.firstName,
                values?.lastName,
                getMonthYearInDigits(values?.expiryDate).monthNumber,
                getMonthYearInDigits(values?.expiryDate).year,
                values?.cvv,
                async (token) => {
                    if (token && token?.id) {
                        const payload = {
                            uId: '',
                            cardToken: token?.id,
                            cardHolderName: userData?.firstName,
                            email: userData?.email,
                            mobileNo: '+52' + userData?.mobileNo,
                            cardNumber: values?.cardNumber,
                            isDefault: values?.cardDefault,
                            isCreditCard: true,
                        };
                        try {
                            const res = await subscriptionService.addCardDetails(payload);
                            handleApiResponse(res, values);
                        } catch (error) {
                            console.error('Error adding card details:', error);
                            throw error;
                        }
                    }
                    console.log('Token created:', token);
                },
                (error) => {
                    console.error('Token creation error:', error);
                    if (error && error?.type === "api_error") {
                        toast.error(state?.labelList?.filter(i => i.key === 'subscription.invalidCardNumber')[0]?.value);
                        setLoading(false)
                    }
                }
            );
        }
    };
    return (
        <>
            <AddCardDetail
                state={state}
                handleAddDetail={handleAddDetail}
                handleApiResponse={handleApiResponse}
                loading={loading}
            />
        </>

    )
}

export default AddCardDetailContainer