import React, { useState, useEffect, useRef } from 'react'
import * as subscriptionService from '../../../service/subscriptionService';
import { getLocalStorage, removeAsyncStorage, removeLocalStorage } from '../../../common/CommonMethods';
import subscriptionDetailState from "./SubscriptionDetailState"
import "../subscriptionModule.css"
import SubscriptionDetail from './SubscriptionDetail';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const SubscriptionDetailContainer = () => {
    const [state, setState] = useState(subscriptionDetailState);
    const [subscriptionPlan, setSubscriptionPlan] = useState([])
    const [loading, setLoading] = useState(false)
    const isComponentMounted = useRef(true);
    const userData = JSON.parse(getLocalStorage('profileInfo'));
    const navigate = useNavigate()

    useEffect(() => {
        getSubscriptionPlans()
    }, [])

    const getSubscriptionPlans = async () => {
        const res = await subscriptionService.getSubscriptionPlanValidation(userData?.uId)
        if (res) {
            setSubscriptionPlan(res)
        }
    }

    const cancelSubscriptionPlan = async () => {
        try {
            setLoading(true)
            await subscriptionService.cancelSubscriptionPlan(userData?.uId)
            toast.success("Cancelled Subscription Successfully")
            removeLocalStorage('modalShown')
            navigate("/active-driver/menu/profile")
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log("error", error)
        }
    }

    useEffect(() => {
        let masterApiData = JSON.parse(getLocalStorage('languageApiLabels'));
        removeAsyncStorage();
        if (isComponentMounted.current) {
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });
        }
        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [state.labelList])



    return (
        <>
            {
                subscriptionPlan &&
                <SubscriptionDetail loading={loading} data={subscriptionPlan} state={state} cancelSubscriptionPlan={cancelSubscriptionPlan} />
            }
        </>
    )
}

export default SubscriptionDetailContainer