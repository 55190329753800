import moment from 'moment';
import { AppEnum } from './AppEnum';
import { compressAccurately } from 'image-conversion';
import * as loginService from '../service/Login.Service';
import * as commonService from '../service/CommonService';
import { toast } from 'react-toastify';
import { decodeJWTToken } from './decode-jwt';
import * as documentService from '../service/document/Document.Service';
import CustomNavigator from './CustomNavigator';
import { isEqual } from 'lodash';
import visa from "../assets/icons/visa.webp";
import mada from "../assets/icons/mada.webp";
import master from "../assets/icons/master.webp";
import stc from "../assets/icons/stc.png";
import { parse, differenceInDays } from "date-fns"

// If partner uploads the same file again then it sets its value to null so that
// alert message can be triggered again
export function SetInputTypeFileFieldNull(event) {
    event.target.value = null;
}

// shows or hides the password field text
export function showHidePassword(event, code) {
    var x;
    if (code === AppEnum.TaxInfoCode.CsdPassword) {
        x = document.getElementById(AppEnum.TaxInfoCode.CsdPassword);
    }
    if (code === AppEnum.TaxInfoCode.FielPassword) {
        x = document.getElementById(AppEnum.TaxInfoCode.FielPassword);
    }
    if (x) {
        if (x.type === "password") {
            x.type = "text";
            event.target.className = event.target.className === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';

        } else {
            x.type = "password";
            event.target.className = event.target.className === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
        }
    }
}

// shows or hides the password field text
export function showHidePasswordLogin(event, fieldName) {
    var y = document.getElementById(fieldName);
    if (y) {
        if (y.type === "password") {
            y.type = "text";
            event.target.className = event.target.className === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
        }
        else {
            y.type = "password";
            event.target.className = event.target.className === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
        }
    }
}

// it prevents the user from entering alphabatic values 
export function updateNumber(event) {
    if (event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 9
        || event.keyCode === 37 || event.keyCode === 39 || event.keyCode === 36 || event.keyCode === 35) {
    }
    else if (event.keyCode < 48 || event.keyCode > 57) {
        if (event.keyCode < 96 || event.keyCode > 105) {
            event.preventDefault();
        }
    }
}

// This function is used to check password strength by testing with regex string
export function checkPasswordStrength(password) {
    const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    const mediumPasswordRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    if (strongPasswordRegex.test(password)) {
        return {
            strength: 'Excellent',
            className: 'strong-password'
        }
    } else if (mediumPasswordRegex.test(password)) {
        return {
            strength: 'Medium',
            className: 'medium-password'
        }
    } else {
        return {
            strength: 'Low',
            className: 'low-password'
        }
    }
}

// Calculates the remaining expiry days of the uploaded document  
export function remainingExpiryDays(expiryDate) {
    if (expiryDate) {
        var start = moment(new Date().toDateString());
        var end = moment(expiryDate);
        var diff = end.diff(start, "days")
        return diff;
    }
    else
        return false
}

// trims the text
export function trimText(str, length) {
    if (str.length > length)
        return str.substring(0, length) + '...';
    else
        return str;
};

// displays the image in new tab
export function showUploadedAttachment(id) {
    var img = document.getElementById(id);
    if (img) {
        let w = window.open(img.src);
        if (!img.src.startsWith('http')) {
            var image = new Image();
            image.src = img.src;
            w.document.write(image.outerHTML);
        }
    }
}

// it compresses the uploaded file/image
export async function compressUploadedFile(file, compressUptoFileSize) {
    const compressedFile = await compressAccurately(file,
        {
            size: compressUptoFileSize,
            type: "image/jpeg",
        }
    ).then((compressedImage) => {
        compressedImage.name = file.name;
        // The compressedImage in the promise is a compressed Blob type (which can be treated as a File type) file;
        return compressedImage;
    })
    return compressedFile;
}

// remove unnecessary async storage
export function removeAsyncStorage() {
    localStorage.removeItem('vehicleSpecification');
    localStorage.removeItem('typeOfService');
    localStorage.removeItem('trainingCategoryUId');
    localStorage.removeItem('vehicleTypeData');
    localStorage.removeItem('vehicleType');
    localStorage.removeItem('sessionId');
}

// removes auth token
export const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('languagecode');
    localStorage.removeItem('modalShown');
}

// removes the provided item from local storage
export const removeLocalStorage = (item) => {
    localStorage.removeItem(item);
}

// sets the value into the local storage
export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
}

// returns the value for provided item name
export const getLocalStorage = (item) => {
    return localStorage.getItem(item);
}

// if token is expired then a new token will be retrieved by the refresh token 
export async function getNewToken() {

    let refreshToken = getLocalStorage('refresh_token');
    let token = getLocalStorage('token');

    if (!refreshToken) {
        CustomNavigator.navigate('/login');
    }
    else {
        let formData = new FormData();
        let decodedToken = decodeJWTToken();

        formData.append('grant_type', 'refresh_token');
        formData.append('client_secret', AppEnum.FormDataEmailCD.client_secret);
        formData.append('client_id', decodedToken?.client_id);
        formData.append('refresh_token', refreshToken);

        setLocalStorage('isTokenRefreshed', true);

        const newToken = await loginService.login(formData).then((refreshTokenResponse) => {

            setLocalStorage('token', refreshTokenResponse.access_token);
            setLocalStorage('refresh_token', refreshTokenResponse.refresh_token);
            removeLocalStorage('isTokenRefreshed');

            return true;
        })
            .catch((err) => {
                if (err) {
                    if (token) {
                        let partnerLanguageApiLabels = JSON.parse(getLocalStorage('languageApiLabels'));
                        toast.error(partnerLanguageApiLabels.filter(i => i.key === 'Validation.SessionExpired')[0]?.value);
                    }
                    removeToken();
                    removeLocalStorage('isTokenRefreshed');
                    CustomNavigator.navigate('/login');
                }
            });

        return newToken;
    }
}

// it calculates the dynamic mobile length validation
export const getMinMaxMobileLength = (masterApiData, dialCode) => {
    let minMobileLength = Number(masterApiData.filter(i => i.key === 'Validation.MobileNoMinLength')[0]?.value);
    let maxMobileLength = Number(masterApiData.filter(i => i.key === 'Validation.MobileNoMaxLength')[0]?.value);
    let countryCode = dialCode.trim().substring(1);

    minMobileLength = minMobileLength - countryCode.length;
    maxMobileLength = maxMobileLength - countryCode.length;

    return { minMobileLength: minMobileLength, maxMobileLength: maxMobileLength }
}

// it decrypts token and returns userUId
export const getUserUIdFromToken = () => {

    const userInfo = decodeJWTToken();
    if (userInfo)
        return userInfo?.tableuidvalue;
    else
        return undefined;
}

export async function getAvailableVehicles() {
    let userUId = getUserUIdFromToken();

    return await documentService.getUserVehicleList(userUId)
        .then((response) => {
            if (response) {

                let isTwoWheelerSelected = response.filter(i => i.vehicleTypeCode === AppEnum.VehicleTypes.Motorcycle)?.length ? true : false;
                let isFourWheelerSelected = response.filter(i => (i.vehicleTypeCode === AppEnum.VehicleTypes.PickUpVan
                    || i.vehicleTypeCode === AppEnum.VehicleTypes.Car))?.length ? true : false;

                return { isTwoWheelerSelected: isTwoWheelerSelected, isFourWheelerSelected: isFourWheelerSelected }
            }
        })
}

// It scrolls the page to the top
export function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
};

// sets the retrieved active country code
export const getActiveCountryList = async (countryCode) => {
    return await commonService.getActiveCountry(countryCode)
        .then(res => {
            if (res)
                return res;
        })
}

/**
 * @param {user vehicle list} userVehicles it takes user vehicle list and returns the available vehicles for the partner
 * @returns 
 */
export function checkVehicleType(userVehicles) {
    let vehicleType = { isTwoWheelerSelected: false, isFourWheelerSelected: false, isBothSelected: false }

    if (userVehicles?.length === 1) {
        vehicleType.isTwoWheelerSelected = (userVehicles[0].vehicleTypeCode === AppEnum.VehicleTypes.Motorcycle
            || userVehicles[0].vehicleTypeCode === AppEnum.VehicleTypes.Bicycle) ? true : false;

        vehicleType.isFourWheelerSelected = (userVehicles[0].vehicleTypeCode === AppEnum.VehicleTypes.PickUpVan
            || userVehicles[0].vehicleTypeCode === AppEnum.VehicleTypes.Car) ? true : false;

        return vehicleType;
    }
    else if (userVehicles?.length > 1) {
        vehicleType.isTwoWheelerSelected = userVehicles.filter(vehicle => (vehicle.vehicleTypeCode === AppEnum.VehicleTypes.Motorcycle
            || vehicle.vehicleTypeCode === AppEnum.VehicleTypes.Bicycle))?.length ? true : false;

        vehicleType.isFourWheelerSelected = userVehicles.filter(vehicle => (vehicle.vehicleTypeCode === AppEnum.VehicleTypes.PickUpVan
            || vehicle.vehicleTypeCode === AppEnum.VehicleTypes.Car))?.length ? true : false;

        if (vehicleType.isTwoWheelerSelected && vehicleType.isFourWheelerSelected)
            vehicleType.isBothSelected = true

        return vehicleType;
    }
    else
        return vehicleType;
}


export function areEqual(prevProps, nextProps) {
    return isEqual(prevProps, nextProps);
}

export const delay = (ms = 150) => {
    return new Promise((resolve) =>
        setTimeout(() => {
            resolve();
        }, ms)
    );
};

export const getMonthYearInDigits = (key = '') => {
    const date = new Date(key);

    const monthNumber = date.getMonth() + 1;

    const year = date.getFullYear() % 100;
    return { monthNumber, year }
}


export function getCurrentLocation() {
    return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    console.log(position.coords)
                    resolve({ latitude, longitude });
                },
                (error) => {
                    reject(error);
                }
            );
        } else {
            reject(new Error("Geolocation is not supported in this browser."));
        }
    });
}

export const getLastFourDigits = (cardNumber) => {
    if (cardNumber) {
        const lastFourDigits = cardNumber.slice(-4);
        return `**** **** **** ${lastFourDigits}`;
    }
    return '';
};


export const getPaymentType = (key) => {
    let img;
    if (key === 'visa') {
        return (img = visa);
    } else if (key === 'mada') {
        return (img = mada);
    } else if (key === 'master') {
        return (img = master);
    }
    else if (key === 'stc') {
        return (img = stc);
    }
    else {
        return;
    }
};

export const getSubscriptionStatus = (data) => {
    if (!data) {
        return "";
    }

    switch (data?.reason) {
        case "Active":
            return data.isDriverSubscriptionActive ? "active" : "";
        case "Not Found":
            return "notFound";
        case "Paused":
            return "paused";
        case "Canceled":
            return "canceled";
        default:
            return "";
    }
}



export const calculateDaysDifference = (trialEndDate) => {
    const parsedDate = trialEndDate && moment(trialEndDate, 'DD-MM-YY').format('MMMM Do YYYY');
    const currentDate = moment();
    const formattedCurrentDate = currentDate.format('MMMM Do YYYY');

    const endDate = parse(parsedDate, 'MMMM do yyyy', new Date());
    const currentDatee = parse(formattedCurrentDate, 'MMMM do yyyy', new Date());

    const daysDifference = differenceInDays(endDate, currentDatee);

    return { parsedDate, daysDifference };
};

