import React, { useEffect, useRef, useState } from "react";
import loginState, { formValidationSchemaMobile } from "./LoginState";
import Geocode from "react-geocode";
import * as commonMethods from "../../common/CommonMethods";
import * as commonService from "../../service/CommonService";
import { AppEnum } from "../../common/AppEnum";
import LoginWithMobile from "./LoginWithMobile";
import { useNavigate } from "react-router-dom";
import "./LoginModule.css";
import personalStateSchema from "../create-account/PersonalInfoState";

let countryCode = null;

const LoginWithMobileContainer = () => {
  const isComponentMounted = useRef(true);
  const navigate = useNavigate();
  const [state, setState] = useState(loginState);
  const formikRef = useRef();
  // // sets several necessary states during component mounting
  useEffect(() => {
    let masterApiData = JSON.parse(
      commonMethods.getLocalStorage("languageApiLabels")
    );
    commonMethods.removeAsyncStorage();
    personalStateSchema.personalInfo = {
      mobileNo: "",
      firstName: "",
      fatherLastName: "",
      motherLastName: "",
      genderCD: "",
      email: "",
      password: "",
      confirmPassword: "",
      referredBy: "",
      cityUId: "",
      stateUId: "",
    };

    if (isComponentMounted.current) {
      getCountryList();
      getLanguageList();
      setState((prevState) => {
        return { ...prevState, labelList: masterApiData };
      });
    }
    window.scrollTo(0, 0);
    return () => {
      isComponentMounted.current = false;
    };
  }, [state.labelList]);

  // // retrives the language list
  const getLanguageList = () => {
    commonService.getlanguageDDO().then((res) => {
      if (res) {
        let languageCode = commonMethods.getLocalStorage("languageCode");
        if (languageCode === AppEnum.LanguageCodeCD.mexican) res.reverse();

        setState((prevState) => {
          return { ...prevState, languageList: res };
        });
      }
    });
  };

  const getCountryList = async () => {
    let country = await commonService.getAllActiveCountry();
    setState((prevState) => {
      return {
        ...prevState,
        countryList: country,
      };
    });
  };

  useEffect(() => {
    getActiveCountryList();
  }, [state.countryList])

  // This method is used to change apps language via api
  const onChangeLanguageListHandler = (languageCode) => {
    let language = "";
    commonMethods.setLocalStorage("languageCode", languageCode);

    if (languageCode === AppEnum.LanguageCodeCD.mexican) {
      language = AppEnum.LanguageListCD.Spanish;
      commonMethods.setLocalStorage("language", language);
    } else if (languageCode === AppEnum.LanguageCodeCD.us) {
      language = AppEnum.LanguageListCD.English;
      commonMethods.setLocalStorage("language", language);
    }

    commonService
      .getLanguageLabels(
        language,
        AppEnum.LanguageApiUserInterfaceCD.Partner_Registration_Web
      )
      .then((res) => {
        if (res) {
          commonMethods.setLocalStorage(
            "languageApiLabels",
            JSON.stringify(res)
          );

          setState((prevState) => {
            return { ...prevState, labelList: res };
          });

          if (formikRef.current) {
            formikRef.current.resetForm();

            setFormValidations(res, state.countryCode);
          }
        }
      });
  };

  // // Retrieves the country list and calls the method to get the current position
  const getActiveCountryList = () => {
    //It calls the method which extracts the current country name according to the location
    //Passes the lat long as the parameter to the method
    navigator.geolocation.getCurrentPosition(getCountryByLatLng, (error) => {
      // new country changes
      getActiveCountryCode("");
    });
  };

  // // It calls the method which extracts the current country name according to the location
  // // and sets the country code in state for the respective country
  const getCountryByLatLng = (position) => {
    Geocode.setApiKey(AppEnum.GoogleApiKey.googleApiKey);
    Geocode.fromLatLng(
      position.coords.latitude,
      position.coords.longitude
    ).then(
      (response) => {
        response.results.every((element) => {
          let address = element?.address_components?.filter((item) => {
            if (item?.types?.includes("country")) {
              return item;
            }
          });
          if (address?.length) {
            countryCode = address[0]?.short_name;
            return false;
          } else return true;
        });
        // new country changes
        countryCode = !countryCode ? "MX" : countryCode;
        getActiveCountryCode(countryCode);
      },
      (error) => {
        // new country changes
        getActiveCountryCode("");
      }
    );
  };

  // sets the retrieved active country code
  const getActiveCountryCode = async (countryCode) => {
    let selectedCountryCode = state.countryList?.find(c => c.value == (countryCode ? countryCode : "MX"));
    if (selectedCountryCode) {
      let masterApiData = JSON.parse(
        commonMethods.getLocalStorage("languageApiLabels")
      );
      setFormValidations(masterApiData, selectedCountryCode.label);
      setState((prevState) => {
        return {
          ...prevState,
          countryCode: selectedCountryCode.label,
          countryUI: selectedCountryCode.value
        };
      });
    }
  };

  // // it sets form validation schema
  const setFormValidations = (masterApiData, dialCode) => {
    let mobileValidation = commonMethods.getMinMaxMobileLength(
      masterApiData,
      dialCode
    );
    let validationSchema = formValidationSchemaMobile(
      masterApiData,
      mobileValidation.minMobileLength,
      mobileValidation.maxMobileLength
    );

    setState((prevState) => {
      return {
        ...prevState,
        mobileNoMaxLength: mobileValidation.maxMobileLength,
        formValidationSchemaMobile: validationSchema,
      };
    });
  };

  // // Routes to login with email page
  const onChangeLoginOptionHandler = () => {
    navigate("/login-with-email");
  };

  // Routes to the signup page
  const signUp = () => {
    navigate("/create-account");
  };

  // // Retrives the otpUId and routes to the otp page
  const onLogin = (loginDetails) => {
    loginDetails.languageCode = commonMethods.getLocalStorage("languageCode");
    let otpDetail = {};

    otpDetail.linkTableCD = AppEnum.LinkTableCD.driver;
    otpDetail.linkTableUId = "";
    otpDetail.secureActionTypeCD = AppEnum.LoginCD.driver;
    otpDetail.languageCode = loginDetails.languageCode;
    otpDetail.sendToMobile = true;
    otpDetail.sendToEmail = false;
    otpDetail.email = "";
    otpDetail.mobileNo = state.countryCode + loginDetails.userName;

    commonService
      .validateLogin(
        otpDetail.mobileNo,
        AppEnum.PartnerTypeCD.driver,
        loginDetails.languageCode
      )
      .then((res) => {
        if (res) {
          otpDetail.otpuId = res.uId;

          navigate("/verify-otp", {
            state: {
              otpDetail: otpDetail,
              loginPartnerType: AppEnum.LoginCD.driver,
              dialCode: state.countryCode,
              otpuId: res.uId,
            },
          });
        }
      });
  };

  return (
    <React.Fragment>
      <LoginWithMobile
        state={state}
        onChangeLanguageListHandler={onChangeLanguageListHandler}
        onChangeLoginOptionHandler={onChangeLoginOptionHandler}
        onChangeCountryListHandler={getActiveCountryCode}
        signUp={signUp}
        onLogin={onLogin}
        formikRef={formikRef}
      />
    </React.Fragment>
  );
};

export default LoginWithMobileContainer;
