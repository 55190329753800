import { useState } from 'react';

function useModal(initialState = false) {
    const [isVisible, setIsVisible] = useState(initialState);

    const show = () => setIsVisible(true);
    const hide = () => setIsVisible(false);

    return [isVisible, show, hide];
}

export default useModal;
