import React from 'react'
import "./subscriptionModule.css"

const ErrorMessage = ({ err }) => {
    return (
        <>
            {err && (
                <span className="invalid-feedback">{err}</span>
            )}
        </>
    )
}

export default ErrorMessage