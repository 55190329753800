import React from 'react'
import Modal from 'react-bootstrap/Modal';
import "../global.css"
import Button from '../../shared-component/Button';
import * as commonMethods from '../../common/CommonMethods';
import * as loginService from '../../service/Login.Service';
import { useNavigate } from 'react-router-dom';

const LogoutConfirmation = ({ show, hide, showSubscriptionModal, labelList }) => {
    const navigate = useNavigate()
    const handle = () => {
        hide()
        showSubscriptionModal(true)
    }

    const logout = () => {
        localStorage.removeItem('sessionId');
        let token = localStorage.getItem('token');
        if (token) {
            let logoutConfig = {
                authToken: token,
                fcmToken: '',
                userUId: commonMethods.getUserUIdFromToken()
            }
            loginService.logout(logoutConfig).then((response) => {
                if (response)
                    routeToLogin();
                else
                    routeToLogin();
            })
                .catch((error) => {
                    if (error)
                        routeToLogin();
                })
        }
        else
            routeToLogin();

        commonMethods.removeAsyncStorage();
    }


    const routeToLogin = () => {
        commonMethods.removeToken();
        navigate('/login');
    }
    return (
        <>
            <Modal className="modals" show={show} >
                <Modal.Body className='edit-modal-body mb-4'>
                    <div className='alert-content'>
                        <p>
                            {labelList.filter(i => i.key === 'logoutConfirmationModal.para')[0]?.value}
                        </p>
                        <div className='buttons'>
                            <Button btnType="primary" label={labelList.filter(i => i.key === 'logoutConfirmationModal.yesBtn')[0]?.value} onClick={() => logout()} />
                            <Button btnType="gray" label={labelList.filter(i => i.key === 'logoutConfirmationModal.noBtn')[0]?.value} onClick={() => handle()} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LogoutConfirmation