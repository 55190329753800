import { Field, Form, Formik } from 'formik'
import React, { useRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import Button from '../../../shared-component/Button'
import FormikInput from '../../../shared-component/formik/FormikInput'
import "../subscriptionModule.css"
import ErrorMessage from '../ErrorMessage'

const AddCardDetail = ({ state, handleAddDetail, loading }) => {

    const { cardDetailsSchema, cardDetails, labelList } = state
    const formikRef = useRef();
    const minAllowedDate = new Date();
    return (
        <div className='container add-card-details'>
            <Formik
                initialValues={cardDetails}
                validationSchema={cardDetailsSchema}
                enableReinitialize={true}
                innerRef={formikRef}
                onSubmit={(values) => {
                    handleAddDetail(values)
                }}>
                {({ errors, values, setFieldValue, isSubmitting }) => (
                    <Form>
                        <div className='profile-div container'>
                            <h2 className='my-3'>{labelList.filter(i => i.key === 'subscription.AddCardDetail')[0]?.value}</h2>
                            <div className="card-detail-input">
                                <label>{labelList.filter(i => i.key === 'subscription.CardNumber')[0]?.value}</label>
                                <Field name="cardNumber" maxLength={16} placeholder="411111111111" id="cardNumber" type="text" component={FormikInput} />
                                <p>{labelList.filter(i => i.key === 'subscription.CardNumberNote')[0]?.value}</p>
                            </div>
                            <div className="card-detail-input">
                                <label>{labelList.filter(i => i.key === 'subscription.FirstName')[0]?.value}</label>
                                <Field name="firstName" id="firstName" placeholder={labelList.filter(i => i.key === 'subscription.FirstNamePlaceHolder')[0]?.value} maxLength="50" type="text" component={FormikInput} />
                            </div>
                            <div className="card-detail-input">
                                <label>{labelList.filter(i => i.key === 'subscription.LastName')[0]?.value}</label>
                                <Field name="lastName" id="lastName" placeholder={labelList.filter(i => i.key === 'subscription.LastNamePlaceholder')[0]?.value} type="text" maxLength="50" component={FormikInput} />
                            </div>
                            <div className='row'>
                                <div className='card-detail-input col-md-6 date-pick'>
                                    <label>{labelList.filter(i => i.key === 'subscription.Validity')[0]?.value}</label>
                                    <ReactDatePicker name="expiryDate"
                                        selected={values?.expiryDate}
                                        onChange={(date) => setFieldValue("expiryDate", date)}
                                        placeholderText="mm/yy"
                                        className='form-control'
                                        minDate={minAllowedDate}
                                        dateFormat="MM /yy" component={FormikInput} />
                                    <ErrorMessage err={errors?.expiryDate} />
                                </div>

                                <div className='card-detail-input col-md-6'>
                                    <label>{labelList.filter(i => i.key === 'subscription.CVV')[0]?.value}</label>
                                    <Field name="cvv" id="cvv" type="number" placeholder="121" component={FormikInput} />
                                </div>
                            </div>
                            <div>
                                <div className='d-flex radio-buttons'>
                                    <Field type="radio" name="cardDefault" value="true" />
                                    <p className='ms-2 '>{labelList.filter(i => i.key === 'subscription.cardOption1')[0]?.value}</p>
                                </div>
                                <div className='d-flex radio-buttons '>
                                    <Field type="radio" name="cardDefault" value="false" />
                                    <p className='ms-2'>{labelList.filter(i => i.key === 'subscription.cardOption2')[0]?.value}</p>
                                </div>
                                <ErrorMessage err={errors?.cardDefault} />
                            </div>
                            <div className='payment-button py-2'>
                                <Button btnLoader={loading} disable={loading} label={labelList.filter(i => i.key === 'subscription.addCardBtn')[0]?.value} btnType="primary" type='submit' />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default AddCardDetail