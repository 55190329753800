import React from 'react'
import Vooy from "../../../assets/logos/Vooy.png"
import Button from '../../../shared-component/Button'
import moment from 'moment'
import { getLastFourDigits } from '../../../common/CommonMethods'
import { useNavigate } from 'react-router-dom'


const CardDetail = ({ data, subscriptionPlan, state, show, subscriptionDetails }) => {
    const { labelList } = state
    const navigate = useNavigate()
    const isSubscriptionActive = subscriptionDetails && subscriptionDetails?.isSubscriptionActive && !subscriptionDetails?.isDriverSubscriptionActive && !subscriptionDetails?.isTrialPeriod

    return (
        <>
            <div className='subscription-plan container card-details'>
                <div className='vooy-image' >
                    <img src={Vooy} />
                </div>
                <div>
                    <h1>
                        {
                            isSubscriptionActive ?
                                labelList.filter(i => i.key === 'subscription.paymentDetails')[0]?.value
                                : labelList.filter(i => i.key === 'subscription.cardDetails')[0]?.value}
                    </h1>
                    <h6>  {isSubscriptionActive ? labelList.filter(i => i.key === 'subscription.cardDescription')[0]?.value : labelList.filter(i => i.key === 'subscription.cardDescription1')[0]?.value}    </h6>
                    {
                        isSubscriptionActive && <div className='total'>
                            <div className='total-section'>
                                <p>{labelList.filter(i => i.key === 'subscription.paymentTotal')[0]?.value}</p>
                                <span>{subscriptionPlan?.price} {subscriptionPlan?.currency}</span>
                            </div>
                        </div>
                    }
                    <div className='detail-container'>
                        <div className='details'>
                            {
                                isSubscriptionActive && <div>
                                    <label>{labelList.filter(i => i.key === 'subscription.subscriptionPlanType')[0]?.value}</label>
                                    <p>{subscriptionPlan?.interval === "month" ? labelList.filter(i => i.key === 'subscription.monthly')[0]?.value : "year" ?
                                        labelList.filter(i => i.key === 'subscription.annually')[0]?.value : labelList.filter(i => i.key === 'subscription.daily')[0]?.value}</p>
                                </div>
                            }

                            <div>
                                <label>{labelList.filter(i => i.key === 'subscription.cardNumberLabel')[0]?.value}</label>
                                <p>{getLastFourDigits(data?.cardNumber)}</p>
                            </div>
                            <div>
                                <label>{labelList.filter(i => i.key === 'subscription.cardHolderName')[0]?.value}</label>
                                <p>  {data?.cardHolderName ? data?.cardHolderName : data?.firstName + " " + data?.lastName} </p>
                            </div>
                            <div>
                                <label>{labelList.filter(i => i.key === 'subscription.cardExpiryMonth')[0]?.value}</label>
                                <p>{moment(data?.expiryDate).format('M')}</p>
                            </div>
                            <div>
                                <label>{labelList.filter(i => i.key === 'subscription.cardExpiryYear')[0]?.value}</label>
                                <p>{moment(data?.expiryDate).format('YY')}</p>
                            </div>
                            <div>
                                <label>{labelList.filter(i => i.key === 'subscription.expiryDate')[0]?.value}</label>
                                <p> {moment(data?.expiryDate).format('Do MMMM YYYY')}</p>
                            </div>
                        </div>
                    </div>
                    <div className='subscription-btn'>
                        {
                            isSubscriptionActive
                                ?
                                <Button btnType="primary" type="submit" label={labelList.filter(i => i.key === 'subscription.payBtn')[0]?.value} onClick={() => show()} />
                                : <Button btnType="primary" type="submit" label={labelList.filter(i => i.key === 'subscription.backToHome')[0]?.value} onClick={() => navigate("/active-driver/menu/profile")} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardDetail