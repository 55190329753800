import * as Yup from 'yup';

export const cardDetailsSchema = (res) => {
    return Yup.object().shape({
        cardNumber: Yup
            .string()
            .matches(/^[0-9]+$/, res?.filter(i => i.key === 'subscription.CardNumberOnlyDigits')[0]?.value)
            .required(res?.filter(i => i.key === 'subscription.CardNumberRequired')[0]?.value)
            .max(16, res?.filter(i => i.key === 'subscription.CardNumberMax')[0]?.value),
        firstName: Yup.string().required(res?.filter(i => i.key === 'subscription.FirstNameRequired')[0]?.value),
        lastName: Yup.string().required(res?.filter(i => i.key === 'subscription.LastNameRequired')[0]?.value),
        cvv: Yup.string().required(res?.filter(i => i.key === 'subscription.CVVRequired')[0]?.value).min(3, res?.filter(i => i.key === 'subscription.CVVMinLength')[0]?.value).max(4, res?.filter(i => i.key === 'subscription.CVVMaxLength')[0]?.value),
        expiryDate: Yup.string().required(res?.filter(i => i.key === 'subscription.ExpiryDateRequired')[0]?.value),
        cardDefault: Yup.string().required(res?.filter(i => i.key === 'subscription.CardStatusRequired')[0]?.value),
    });
}

export const cardDetails = () => {
    return {
        cardNumber: '',
        firstName: '',
        lastName: '',
        cvv: '',
        expiryDate: ''
    }
}

const cardState = (function states() {
    return {
        cardDetails: cardDetails(),
        cardDetailsSchema: cardDetailsSchema(),
        labelList: [],
        languageList: [],
    }
})()

export default cardState;