const conektaHelper = {
    initConekta: () => {
        window.Conekta.setPublicKey("key_CgUPNxwfyD705K3kMyMQEBT")
    },
    tokenize: (cardNumber, cardFirstName, cardLastName, expiryMonth, expiryYear, cvc, successCallback, errorCallback) => {
        const tokenParams = {
            card: {
                number: cardNumber,
                name: `${cardFirstName} ${cardLastName}`,
                exp_year: expiryYear,
                exp_month: expiryMonth,
                cvc
            }
        }
        window.Conekta.Token.create(tokenParams, successCallback, errorCallback)
    }
}

export default conektaHelper
