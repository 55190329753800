import React from 'react';
import { areEqual } from '../../common/CommonMethods';
import "./button.css"

const MemoComponent = ({
    label,
    disable,
    onClick = undefined,
    type,
    btnLoader,
    style,
    className,
    icon,
    btnType,
    innerRef
}) => {
    return (
        <button
            ref={innerRef}
            disabled={btnLoader || disable}
            style={style}
            onClick={onClick}
            className={`${className}  ${btnType === 'primary' ? "primaryButton " : btnType === "secondary" ? "secondaryButton" : btnType === 'danger' ? "dangerButton" : btnType === 'gray' ? "gray" : ""}`}
            type={type} >
            {
                btnLoader ? (
                    <i className="fa fa-spinner" ></i>
                ) : (
                    <>
                        {icon || ''} {label}
                    </>
                )}
        </button >
    );
};
const Button = React.memo(MemoComponent, areEqual);

export default Button;
