import React, { useEffect, useRef, useState } from 'react'
import CardDetail from './CardDetail'
import { useLocation } from 'react-router-dom';
import { getLocalStorage, removeAsyncStorage } from '../../../common/CommonMethods';
import * as subscriptionService from '../../../service/subscriptionService';
import CardDetailState from './CardDetailState';
import CardSuccess from '../../../modals/card-sucess/CardSucess';
import { toast } from 'react-toastify';
import PayConfirmation from '../../../modals/pay-confirmation/PayConfirmation';


const CardDetailContainer = () => {
    const location = useLocation();
    const data = location?.state?.values;
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [state, setState] = useState(CardDetailState);
    const [showPayConfirmationModal, setShowPayConfirmationModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleClose = () => setShowSuccessModal(false);
    const handleShow = () => setShowSuccessModal(true);
    const handlePayConfirmationClose = () => setShowPayConfirmationModal(false);
    const handlePayConfirmationShow = () => setShowPayConfirmationModal(true);
    const [subscriptionPlan, setSubscriptionPlan] = useState([])
    const isComponentMounted = useRef(true);
    const userData = JSON.parse(getLocalStorage('profileInfo'));
    const subscriptionDetails = JSON.parse(getLocalStorage('subscriptionDetails'));


    useEffect(() => {
        getSubscriptionPlans()
    }, [])

    const getSubscriptionPlans = async () => {
        const response = await subscriptionService.getSubscriptionPlan()
        if (response) {
            setSubscriptionPlan(response)
        }
    }

    useEffect(() => {
        let masterApiData = JSON.parse(getLocalStorage('languageApiLabels'));
        removeAsyncStorage();
        if (isComponentMounted.current) {
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });
        }
        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [state.labelList])


    const addSubscriptionPlan = async () => {
        setLoading(true)
        let payload = {
            "cardUId": data?.uId || "",
            userUId: userData?.uId || '',
            paymentModeCD: "CREDIT_CARD",
            paymentCurrency: "MXN",

        }
        const res = await subscriptionService.createSubscriptionPlan(payload)
        handlePayConfirmationClose()
        setLoading(false)
        if (res && res?.paymentStatus === "Approved") {
            setShowSuccessModal(true)
        } else if (res && res?.failedReason) {
            toast.error(res?.failedReason)
        }
    }

    return (
        <>
            <CardDetail data={data} show={handlePayConfirmationShow} subscriptionPlan={subscriptionPlan} state={state} subscriptionDetails={subscriptionDetails} />
            {showSuccessModal ?
                <CardSuccess show={handleShow} hide={handleClose} state={state} /> : null}

            {
                showPayConfirmationModal &&
                <PayConfirmation loading={loading} show={handlePayConfirmationShow} addSubscriptionPlan={addSubscriptionPlan} hide={handlePayConfirmationClose} state={state} />
            }
        </>
    )
}

export default CardDetailContainer