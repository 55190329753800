import { toast } from 'react-toastify';
import { AppEnum } from './AppEnum';
import { getLocalStorage } from './CommonMethods';
import * as commonMethods from '../common/CommonMethods';
import * as commonService from '../service/CommonService';
import CustomNavigator from './CustomNavigator';

export function PrivateLayout() {

    const token = getLocalStorage('token');
    if (token) {
        let masterApiData = JSON.parse(commonMethods.getLocalStorage('languageApiLabels'));
        userStatusIsNotApproved(masterApiData);
    }
    else {
        CustomNavigator.navigate('/login');
        return;
    }
}

const userStatusIsNotApproved = (masterApiData) => {

    commonService.validateUser().then(async(response) => {
        if (response) {

            if (response.isNewRegister)
                routeToPersonalInfoPage(response.isNewRegister);
            else {
                let { isBikeAvailable, isCarAvailable, isBicycleAvailable } = await commonService.getUserVehicleList()


                let validVehicle = checkForPersonalDocsToUpload(response.userVehicles);
                let isExamPending = checkForPendingExam(response);
                let documentsToUpload = checkForPersonalDocsToUpload(
                    response.personalDocuments,
                    isBicycleAvailable
                  );
                let navigateToDocs =
                  !validVehicle || documentsToUpload?.length || isExamPending
                    ? true
                    : false;
                // let vehicleType = commonMethods.checkVehicleType(response.userVehicles);
                let vehicleType = { isTwoWheelerSelected: false, isFourWheelerSelected: false, isBicycle: false };
                let vehicleTypeCode = response.userVehicles[0]?.vehicleDocuments[0]?.vehicleTypeCode;

                (vehicleTypeCode === AppEnum.VehicleTypes.Motorcycle
                    || vehicleTypeCode === AppEnum.VehicleTypes.Bicycle) ?
                    vehicleType.isTwoWheelerSelected = true : vehicleType.isFourWheelerSelected = true;

                if (vehicleTypeCode === AppEnum.VehicleTypes.Bicycle)
                    vehicleType.isBicycle = true;

                let isDocumentEditable = checkIfDocumentIsRejected(true, response, vehicleType);
                let hasEnabledVehicle = response.userVehicles.filter(vehicle => vehicle.isEnable)?.length ? true : false;

                if (response.userProfileStatus === AppEnum.UserProfileStatusCD.Blocked || response.userProfileStatus === AppEnum.UserProfileStatusCD.Unsubscribe) {
                    removeAsyncStorage();
                    toast.error(masterApiData.filter(i => i.key === 'Validation.YourAccountIsBlocked')[0]?.value);
                }
                else if (response.userProfileStatus === AppEnum.UserProfileStatusCD.Deleted || response.userProfileStatus === AppEnum.UserProfileStatusCD.DeletedPermanently) {
                    removeAsyncStorage();
                    toast.error(masterApiData.filter(i => i.key === 'Validation.ProfileIsDeleted')[0]?.value);
                }
                else if (response.isProfileActivated && hasEnabledVehicle) {
                    if (response.userProfileStatus === AppEnum.UserProfileStatusCD.Approved) {

                        let isPersonalDocumentUploaded = checkIfPersonalDocumentUploaded(true, response);
                        if (isPersonalDocumentUploaded) {
                            commonService.checkForActiveOrders()
                                .then((result) => {
                                    if (result)
                                        toast.error(masterApiData.filter(i => i.key === 'Validation.ActiveOrder')[0]?.value);
                                    else {
                                        commonMethods.setLocalStorage('vehicleType', JSON.stringify(vehicleType));
                                        CustomNavigator.navigate('/active-driver/menu/profile');
                                    }
                                })
                        }
                        else {
                            CustomNavigator.navigate("/driver/documents");
                        }
                    }
                    else
                        profileIsUnderReview(masterApiData);
                }
                else {
                    let isExamPassed = (response.fourWheelerExamStatus?.toUpperCase() === AppEnum.SubmissionStatusCD.Passed ? true : false);

                    let isVehicleRejected = (response.userVehicles[0]?.vehicleStatusCD === AppEnum.VehicleStatusCD.Rejected
                        || response.userVehicles[0]?.vehicleStatusCD === AppEnum.VehicleStatusCD.Expired) ? true : false;

                    let isVehicleExpired = (response.userVehicles.length > 1 && !isVehicleRejected
                        //  response.userVehicles.filter(i => (i.VehicleStatusCD === AppEnum.VehicleStatusCD.Expired|| i.VehicleStatusCD === AppEnum.VehicleStatusCD.Expired))?.length
                        && response.userVehicles.filter(i => i.isEnable)?.length === 0) ? true : false
                                                
                    if (response.userProfileStatus === AppEnum.UserProfileStatusCD.Approved && !response.isProfileActivated &&
                        response.userIdentityStatus === AppEnum.IdentityStatusCD.Approved && response.isVehicleAvailable && !isDocumentEditable) {
                        profileIsUnderReview(masterApiData);
                    }
                    else if(response.userProfileStatus === AppEnum.UserProfileStatusCD.Expired && !response.isProfileActivated &&
                        response.userIdentityStatus === AppEnum.IdentityStatusCD.Approved && response.isVehicleAvailable){
                            CustomNavigator.navigate("/driver/documents");
                    }
                    else if (response.userProfileStatus === AppEnum.UserProfileStatusCD.Rejected) {
                        routeToPersonalInfoPage(false);
                    }
                    else if (response.userProfileStatus === AppEnum.UserProfileStatusCD.Registered || !response.isVehicleAvailable) {
                        CustomNavigator.navigate("/driver/service-type");
                    }
                    else if (isVehicleRejected && vehicleTypeCode !== AppEnum.VehicleTypes.Bicycle) {
                        checkServiceTypes(response.userServices);

                        let vehicleData = response.userVehicles[0];

                        let vehicleTypeData = {
                            vehicleTypeCode: vehicleData.vehicleTypeCode,
                            vehicleTypeId: vehicleData.vehicleTypeId,
                            vehicleTypeUId: vehicleData.vehicleTypeUId,
                        }
                        let vehicleSpecification = {
                            vehicleBrandUId: vehicleData.vehicleBrandUId,
                            vehicleSubBrand: vehicleData.vehicleSubBrand,
                            model: vehicleData.model,
                            vehicleColorCD: vehicleData.vehicleColorCD,
                            plateNumber: vehicleData.plateNumber,
                            vehicleBrandName: vehicleData.vehicleBrandName,
                            remark: vehicleData.remarks,
                            uId: vehicleData.uId,
                            isSpecialAbility: vehicleData.isSpecialAbility,
                            isPetFriendly: vehicleData.isPetFriendly
                        }
                        commonMethods.setLocalStorage('vehicleTypeData', JSON.stringify(vehicleTypeData));
                        commonMethods.setLocalStorage('vehicleSpecification', JSON.stringify(vehicleSpecification));
                        CustomNavigator.navigate("/driver/vehicle-specification", { state: { isUpdateFlow: true } });
                    }
                    else if (isVehicleExpired) {
                        commonMethods.removeToken();
                        toast.error(masterApiData.filter(i => i.key === 'Validation.EnableSecondVehicle')[0]?.value);
                        CustomNavigator.navigate('/login');
                    }
                    else if ((isDocumentEditable || (vehicleType.isFourWheelerSelected ? !isExamPassed : false)) && !hasEnabledVehicle) {
                        checkServiceTypes(response.userServices);
                        CustomNavigator.navigate("/driver/documents");
                    }else if (navigateToDocs){
                        CustomNavigator.navigate("/driver/documents");
                    }
                    else {
                        profileIsUnderReview(masterApiData);
                    }
                }
            }
        }
    })
}

// routes to the personal page and passes the personal data 
const routeToPersonalInfoPage = (isNewRegister) => {
    commonService.getUserProfile().then((res) => {
        if (res) {
            CustomNavigator.navigate('/create-account',
                {
                    state: {
                        personalData: res,
                        remark: isNewRegister ? isNewRegister : res.rejectionReason
                    }
                }
            );
        }
    })
    return;
}

// it checks if any document is rejected/not uploaded or expired
const checkIfPersonalDocumentUploaded = (isExpired, response) => {
    // checking for all personal documents
    let isPersonalDocumentUpdated = response.personalDocuments.filter(attachment =>
    (((attachment.isAttachment && !attachment.attachmentUId) || (!attachment.isAttachment && !attachment.titleValue)) ||
        ((attachment.attachmentRemark || attachment.remark) && !attachment.isApproved) ||
        (isExpired ? (attachment.expiryDateTime ? (commonMethods.remainingExpiryDays(new Date(attachment.expiryDateTime)) < 0) : false) : false)));
    return (isPersonalDocumentUpdated == null || isPersonalDocumentUpdated.length == 0);
}

// it checks if any document is rejected or any document is expired
const checkIfDocumentIsRejected = (isExpired, response, vehicleType) => {

    if (vehicleType.isTwoWheelerSelected && !vehicleType.isFourWheelerSelected)
        response.personalDocuments = response.personalDocuments?.filter(i => i.code !== AppEnum.PersonalDocumentCode.DL);
    if (!vehicleType.isTwoWheelerSelected && vehicleType.isFourWheelerSelected)
        response.personalDocuments = response.personalDocuments?.filter(i => i.code !== AppEnum.PersonalDocumentCode.DlLite);

    if (vehicleType.isBicycle) {
        response.personalDocuments = response.personalDocuments.filter(i => (i.code === AppEnum.PersonalDocumentCode.IdentityDocument
            || i.code === AppEnum.PersonalDocumentCode.ProfilePicture || i.code === AppEnum.PersonalDocumentCode.TaxDocument));
    }

    // checking for personal documents rejection
    let isPersonalDocumentEditable = response.personalDocuments?.some(attachment =>
    (((attachment.isAttachment && !attachment.attachmentUId) || (!attachment.isAttachment && !attachment.titleValue)) ||
        ((attachment.attachmentRemark || attachment.remark) && !attachment.isApproved) ||
        (isExpired ? (attachment.expiryDateTime ? (commonMethods.remainingExpiryDays(new Date(attachment.expiryDateTime)) < 0) : false) : false)));

    let vehicleDocuments = response.userVehicles[0]?.vehicleDocuments;

    // checking for vehicle documents rejection
    let isVehicleDocumentEditable = vehicleDocuments?.some(attachment =>
    ((attachment.isAttachment && !attachment.attachmentUId) || (attachment.remark && !attachment.isApproved) ||
        (isExpired ? (attachment.expiryDateTime ? (commonMethods.remainingExpiryDays(new Date(attachment.expiryDateTime)) < 0) : false) : false)))

    let isDocumentEditable = isPersonalDocumentEditable || isVehicleDocumentEditable;
    return isDocumentEditable;
}

// it returns all the service types
const checkServiceTypes = (userServices) => {

    let userServicesList = [];
    userServices.forEach((service) => {
        userServicesList.push(service.serviceTypeId);
    });
    commonMethods.setLocalStorage('typeOfService', JSON.stringify(userServicesList));
}

// removes the async storage and routes to the login page 
const removeAsyncStorage = () => {
    commonMethods.removeToken();
    CustomNavigator.navigate('/login');
}

// if profile is under review then it routes to the login page
const profileIsUnderReview = (masterApiData) => {
    commonMethods.removeToken();
    toast.success(masterApiData.filter(i => i.key === 'Validation.ProfileIsUnderReview')[0]?.value);
    CustomNavigator.navigate('/login');
}

function checkForPersonalDocsToUpload(personalDocuments, isBicycleAvailable) {
    let bicycleDocToUpload = personalDocuments?.filter(attachment =>
      (attachment.code === AppEnum.PersonalDocumentCode.IdentityDocument || attachment.code === AppEnum.PersonalDocumentCode.ProfilePicture || attachment.code === AppEnum.PersonalDocumentCode.TaxDocument) &&
      (((attachment.isAttachment && !attachment.attachmentUId) || (!attachment.isAttachment && !attachment.titleValue)) ||
        ((attachment.attachmentRemark || attachment.remark) && !attachment.isApproved) ||
        (attachment.isApproved && attachment.code !== AppEnum.PersonalDocumentCode.SsnNumber && commonMethods.remainingExpiryDays(new Date(attachment.expiryDateTime), new Date()) < 0))
    );
    let documentsToUpload = personalDocuments?.filter(attachment =>
      ((attachment.isAttachment && !attachment.attachmentUId) || (!attachment.isAttachment && !attachment.titleValue)) ||
      ((attachment.attachmentRemark || attachment.remark) && !attachment.isApproved) ||
      (attachment.isApproved && attachment.code !== AppEnum.PersonalDocumentCode.SsnNumber && commonMethods.remainingExpiryDays(new Date(attachment.expiryDateTime), new Date()) < 0)
    );
    return isBicycleAvailable ? bicycleDocToUpload : documentsToUpload;
  }



   function checkForPendingExam(response) {
    let isExamPending = (response.userVehicles?.length &&
      (response.userVehicles[0]?.vehicleTypeCode === AppEnum.VehicleTypes.Car || response.userVehicles[0]?.vehicleTypeCode === AppEnum.VehicleTypes.PickUpVan) &&
      response.fourWheelerExamStatus !== AppEnum.SubmissionStatusCD.Passed) ? true : false;
  
    return isExamPending;
  }