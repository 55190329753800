import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Success from "../../assets/icons/success.png"
import "../global.css"
import { useNavigate } from 'react-router-dom';
import Button from '../../shared-component/Button';

const CardSuccess = ({ show, hide, state }) => {
    const navigate = useNavigate()

    const { labelList } = state
    const navigationTowardsHome = () => {
        hide()
        navigate("/active-driver/menu/profile")
    }
    return (
        <>
            <Modal className="modals" show={show} >
                <Modal.Body className='edit-modal-body'>
                    <div className='alert-icon'>
                        <img src={Success} alt="Excellent Password" />
                    </div>
                    <div className='alert-content'>
                        <p>
                            {labelList.filter(i => i.key === 'subscription.subscrtiptionSuccessModalPoint1')[0]?.value}
                        </p>
                        <Button btnType="primary" label={labelList.filter(i => i.key === 'subscription.subscriptionPlanSuccessBtn')[0]?.value} onClick={() => navigationTowardsHome()} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CardSuccess